import image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import Img from '../../img/img'

const Image = (props: any) => {
  const { image: imageProps }: any = props
  const Images = ({ url, mode }: any) => {
    const imgStyle = props.mode?.web?.style
    return (
      <div>
        {imgStyle == 'style1' ? (
          <div className="w-40 ml-10">
            <Img
              rWidth={{
                mobileweb: 390,
                web: 1000,
              }}
              rHeight={{
                mobileweb: 390,
                web: 1000,
              }}
              deviceType={props?.deviceMode}
              src={`${
                !!url ? url : 'https://img.poorvika.com/common/Noimage.png'
              }`}
              alt="Product Card"
              blurDataURL={`${
                !!url ? url : 'https://img.poorvika.com/common/Noimage.png'
              }`}
              //placeholder="blur"
              style={{
                height: '100%',
                width: '100%',
                borderRadius: props.mode?.web?.radius,
              }}
              priority
            />
          </div>
        ) : imgStyle == 'style2' ? (
          <div className={`image2`}>
            <Img
              rWidth={{
                mobileweb: 390,
                web: 1000,
              }}
              rHeight={{
                mobileweb: 390,
                web: 1000,
              }}
              deviceType={props?.deviceMode}
              src={`${
                !!url ? url : 'https://img.poorvika.com/common/Noimage.png'
              }`}
              alt="Product Card"
              blurDataURL={`${
                !!url ? url : 'https://img.poorvika.com/common/Noimage.png'
              }`}
              //placeholder="blur"
              style={{
                height: '100%',
                width: '100%',
                borderRadius: props.mode?.web?.radius,
              }}
              priority
            />
          </div>
        ) : (
          <img
            src={`${
              !!url ? url : 'https://img.poorvika.com/common/Noimage.png'
            }`}
            alt="Product Card"
            //placeholder="blur"
            style={{
              height: '100%',
              width: '100%',
              borderRadius: props.mode?.web?.radius,
            }}
          />
        )}
      </div>
    )
  }
  const bannerLink = (element: any) => {
    switch (element.linktype) {
      case 'EXTERNAL':
        return `${element.link.url}`
      case 'PRODUCT':
        if (element.product.pd_type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.product.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element.product.code}\`]`,
            },
          }
        }
      case 'TAG':
        if (element.tag?.tagtype == 'PRODUCT') {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        }

      case 'PAGE':
        if (element.page.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'group',
              slug: element.page.code,
            },
          }
        } else if (element.page.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: element.page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: element.page.code,
            },
          }
        }

      default:
        return `${element.slug}`
    }
  }
  const Lnk = (props: any) => {
    const { children } = props
    return props.linktype == 'NONE' ? (
      <>{children}</>
    ) : (
      <Link
        href={bannerLink(props)}
        passHref
        target={props.link?.target}
        className={props.className}
      >
        {children}
      </Link>
    )
  }

  return (
    <Lnk {...props}>
      <Images {...imageProps} />
    </Lnk>
  )
}
export default Image
