import React, { FC, useCallback, useEffect, useState } from 'react'
import { PimHomeService } from '~/services/pim.service'
import { Logger } from '~/util'
import { VideoCard } from '../../cards/video-card/video-card'
import { CloseNew } from '../../icons/close_new'
import Model from '../../model/model'
import VideoScrollWrapper, {
  VideoScrollType,
} from '../../videoplay-scroll-wrapper/videoplay-scroll-wrapper'
import style from '~/styles/home.module.scss'
import Img from '../../img/img'
interface Datum {
  __v: number
  _id: string
  brand?: any
  category?: any
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  model?: any
  modifiedby: string
  modifieddate: string
  name: Name
  org_code?: any
  published: Published
  shape: string
  stats: any[]
  type: string
  variant?: any
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name {
  all: string
  'en-IN': string
  'ta-IN': string
}

interface List {
  attr: Attr
  id: number
}

interface Attr {
  all: All
}

interface All {
  controls: boolean
  img: string
  loop: boolean
  playerType: string
  videoID: string
}

type HeadContentProps = {
  image: string
  title: string
  isHeader: any
  header: any
}

interface VideoProps {
  controls: boolean
  img: string
  loop: boolean
  playerType: string
  videoID: string
}

const HeadContent: FC<HeadContentProps> = (props) => (
  <div className="flex align-middle items-center">
    {props.isHeader == true && (
      <>
        {!!props.header.image ? (
          <img
            height={50}
            width={50}
            src={props?.header?.image}
            alt="image"
            //placeholder="blur"
          />
        ) : (
          ''
        )}
        <span
          className="pl-4 inline-block"
          style={{
            color: props?.header?.style?.fontColor,
            fontSize: props?.header?.style?.fontSize,
            fontWeight: props?.header?.style?.fontStyle?.isBold && 'bold',
            fontStyle: props?.header?.style?.fontStyle?.isItalic && 'italic',
            textDecoration:
              props?.header?.style?.fontStyle?.isUnderLine && 'underline',
          }}
        >
          {props?.header?.title && props.header.title}
        </span>
      </>
    )}
  </div>
)

const Videogrouplist = (props: any) => {
  const [selectedVideo, setSelectedVideo] = useState(null as any)
  const [videoType, setVideoType] = useState({} as any)
  const variantClassMap: any = {
    Youtube: 'https://www.youtube.com/embed/',
    Vimeo: 'https://player.vimeo.com/video/',
    Dailymotion: 'https://www.dailymotion.com/embed/video/',
  }
  const hasVideo = useCallback(() => {
    return !!selectedVideo
  }, [selectedVideo])

  return (
    <div>
      {!!props.items?.length && (
        <VideoScrollWrapper
          type={VideoScrollType.NORMAL}
          products
          sliderButton
          //title={props.header.title}
          headerHtml={
            <HeadContent {...(props?.mode && props.mode[props?.deviceMode])} />
          }
        >
          {props.items?.map((e: any, i: number) => {
            return (
              <VideoCard
                {...e}
                play={() => {
                  setSelectedVideo(
                    `${variantClassMap[e.attr?.playerType]}${
                      e.attr?.videoID
                    }?autoplay=${e.attr?.loop ? 1 : 0}&loop=${
                      e.attr?.loop ? 1 : 0
                    }&controls=${e.attr?.controls ? 1 : 0}&playlist=${
                      e.attr?.videoID
                    }`
                  )
                  setVideoType(e.attr)
                }}
                data={e}
                key={i}
              />
            )
          })}
        </VideoScrollWrapper>
      )}
      {hasVideo() ? (
        <div>
          <Model closeModel={() => setSelectedVideo(null)} model={hasVideo()}>
            <div
              className={`${style.shareclose}`}
              onClick={() => setSelectedVideo(!hasVideo)}
            >
              <CloseNew className={`${style.closebutton}`} />
            </div>
            {videoType.playerType == 'Video' ? (
              <video
                className={style.home_iframe}
                src={videoType.url}
                controls={true}
              />
            ) : (
              <iframe
                className={style.home_iframe}
                src={selectedVideo}
                title="YouTube video player"
                allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            )}
          </Model>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
export default Videogrouplist
