import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import Star from '../../icons/star'
import Img from '../../img/img'
import style from './producteleven.module.scss'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: any
}

const ProductEleven: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={`${style.card}`}>
      <div className={style.card_pos}>
        <Link href={link} passHref target={target}>
          <Img
            rWidth={{
              mobileweb: 150,
              web: 168,
            }}
            rHeight={{
              mobileweb: 150,
              web: 168,
            }}
            deviceType={props.props.mode}
            //placeholder="blur"
            className={style.image}
            src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
            alt="Product Card"
            loader={() =>
              `${props.data.thumb ? props.data.thumb : props.data.image}`
            }
            blurDataURL={`${
              props.data.thumb ? props.data.thumb : props.data.image
            }`}
          />
        </Link>
        <div className={style.card__heart_icon}>
          <FavouriteButton
            item_code={props.data.item_code}
            slug={props.data.code}
            pimStyle={props.styles}
          />
        </div>
      </div>
      <div>
        <Link href={link} target={target}>
          <div className={style.card__description}>
            <h3>{props.data.name}</h3>
            <h4>{props.data.option_value}</h4>
          </div>
          <div className={style.card__description__bottom}>
            <div className={style.price}>
              <p>
                {sellingprice?.length != 0
                  ? PriceFormat(sellingprice[0]?.price)
                  : PriceFormat(mrpprice[0]?.price)}
              </p>
              <div className={style.rating_icon}>
                {props.data.rating && props.data.review_count > 0 && (
                  <div className="flex items-center">
                    <Star color="#f46b27" type="FILL" size="0.8" />
                    <span>{props.data.rating}</span>
                  </div>
                )}
              </div>
            </div>
            {/* {Number(props.data.special_price) !== 0 && ( */}
            <div className={`${style.savings}`}>
              <span className={style.save}>
                {Number(mrpprice[0]?.price) - Number(sellingprice[0]?.price) >
                  0 && (
                  <span>
                    Save{' '}
                    {PriceFormat(
                      Number(mrpprice[0]?.price) -
                        Number(sellingprice[0]?.price)
                    )}
                  </span>
                )}
              </span>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              ) > 0 && (
                <span className={style.offer}>
                  ({' '}
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  )}
                  % OFF )
                </span>
              )}
            </div>
            {/* )} */}
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ProductEleven
