import Link from 'next/link'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import { PriceFormat, IsMobile } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import { HomeProductCardListProps } from '../home-product-card/home-product-cardlist.interface'
import style from './home-product-card.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'

/**
 * product card renders Product card this will have fav icon and add to card button
 * @category Components
 * @subcategory ProductCardlist
 * @param {ProductCardListProps} props product card related data
 * @returns {JSX.Element} jsx value
 */
export const HomeProductCard: FC<HomeProductCardListProps> = (props: any) => {
  const [target, setTarget] = useState('_blank')
  const ref = useRef(null)
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data.mrp[0]?.price)
  const loadDataOnlyOnce = () => {}
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
      loadDataOnlyOnce()
    }
    if (ref.current) {
      ref.current.style.color = '#3b3b3b'
      ref.current.style.fontSize = '11px'
      ref.current.style.fontWeight = '500'
      ref.current.style.paddingLeft = '8px'
    }
  }, [])

  return (
    <div>
      <div className={`${style.image_style}`}>
        <div className="grid grid-cols-2 gap-2">
          <div className="grid place-content-center justify-end">
            <div
              className={`${style.prop_rel} ${
                props.prodstyl == 'NONE' ? '' : `${style.layout_center}`
              }`}
            >
              {/* <div className={style.pos_abs_desc}>{props.data.name}</div> */}
              <Link href={link} target={target}>
                <div className="text-left">
                  <h3>{props.data.name}</h3>
                  <h4
                    style={{
                      visibility: `${
                        props.data.option_value ? 'visible' : 'hidden'
                      }`,
                    }}
                  >
                    {props.data.option_value}
                  </h4>

                  <div
                    className={`${style.card_top} ${
                      props.prodstyl == 'NONE' ? '' : `${style.grid_price}`
                    }`}
                  >
                    <h5>
                      {sellingprice[0]?.price != ''
                        ? PriceFormat(sellingprice[0]?.price)
                        : PriceFormat(mrpprice[0]?.price)}
                    </h5>
                    <div>
                      {!!props.data.rating && props.data.review_count > 0 && (
                        <p className="flex items-center">
                          <Star color="#f46b27" type="FILL" />
                          <b style={{ color: 'black' }}>{props.data.rating}</b>
                          <span className="ml-2">
                            ({props.data.review_count})
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`${style.card_bottom} ${
                    props.prodstyl == 'NONE' ? '' : `${style.grid_price}`
                  }`}
                >
                  <h5
                  // style={{
                  //   visibility: `${
                  //     props.data.discount ? 'visible' : 'hidden'
                  //   }`,
                  // }}
                  >
                    {Math.round(
                      100 -
                        (Number(sellingprice[0]?.price) /
                          Number(mrpprice[0]?.price)) *
                          100
                    ) > 0 &&
                      (Number(mrpprice[0]?.price) >=
                      Number(sellingprice[0]?.price)
                        ? PriceFormat(mrpprice[0]?.price)
                        : PriceFormat(sellingprice[0]?.price))}
                  </h5>
                  <p>
                    {Math.round(
                      100 -
                        (Number(sellingprice[0]?.price) /
                          Number(mrpprice[0]?.price)) *
                          100
                    ) > 0 && (
                      <span>
                        {Math.round(
                          100 -
                            (Number(sellingprice[0]?.price) /
                              Number(mrpprice[0]?.price)) *
                              100
                        )}
                        % OFF
                      </span>
                    )}
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="grid justify-items-start">
            <Link href={link} target={target}>
              <Img
                rWidth={{
                  mobileweb: 150,
                  web: 300,
                }}
                rHeight={{
                  mobileweb: 150,
                  web: 300,
                }}
                deviceType={props.mode}
                src={`${
                  props.data.thumb
                    ? props.data.thumb
                    : props.data.image
                    ? props.data.image
                    : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
                }`}
                alt="thumb"
                blurDataURL={`${
                  props.data.thumb
                    ? props.data.thumb
                    : props.data.image
                    ? props.data.image
                    : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
                }`}
                //placeholder="blur"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
