import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ProductCardProps } from './product-card.interface'
import style from './product-card.module.scss'
import { CartState } from '~/stores/cart'
import { IsMobile, PriceFormat } from '~/util'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { UseNotificationState } from '~/stores/notify'
import ProductOne from '../product-cards/product-one'
import ProductTwo from '../product-cards/product-two'
import ProductThree from '../product-cards/product-three'
import ProductFour from '../product-cards/product-four'
import ProductTwelve from '../product-cards/product-twelve'
import ProductSeventeen from '../product-cards/product-seventeen'
import ProductThirteen from '../product-cards/product-thirteen'
import ProductEighteen from '../product-cards/product-eighteen'
import Link from 'next/link'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import Star from '../../icons/star'
import ProductFifteen from '../product-cards/product-fifteen'
import ProductSixteen from '../product-cards/product-Sixteen'
import ProductEleven from '../product-cards/product-eleven'
import ProductFourteen from '../product-cards/product-fourteen'
import { CartBtn } from '~/components/pages/cart/cart-btn/cart-btn'
import { DetailStore } from '~/stores/detail'
import ProductSeven from '../product-cards/product-seven'

const Img: any = dynamic(() => import('~/components/ui/img/img'))
/**
 * product card renders Product card this will have fav icon and add to card button
 * @category Components
 * @subcategory ProductCard
 * @param {ProductCardProps} props product card related data
 * @returns {JSX.Element} jsx value
 */
export const ProductCard: FC<ProductCardProps> = (props) => {
  const styles = props.productStyles

  const cartState: any = CartState.useContainer()
  const notify = UseNotificationState.useContainer()
  // const productDetail = DetailStore.useContainer()
  const mounted = useRef<any>()
  const [_existInCart, setExistInCart] = useState<boolean>(false)
  const router = useRouter()
  /**
   * add items to wish list
   * @returns {Promise<Void>}
   */

  /**
   * remove item from wish list
   * @returns {Promise<Void>}
   */

  /**
   * calculate discount percentage
   * @param percentValue percentage value
   * @param originalValue original value
   * @returns {Number}
   */
  const percentCalculate = (percentValue: number, originalValue: number) =>
    Math.floor((percentValue / originalValue) * 100)

  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data.slug,
    },
  }
  // if (!!notify.storeName) {
  //   link.query['store'] = notify.storeName
  // }

  useEffect(() => {
    cartState.items.map((item: any) => {
      if (item.item_code === props.data.item_code) {
        setExistInCart(true)
      }
    })
  }, [props.data.item_code, cartState])

  const [_wMounted, setWMounted] = useState(false)
  useLayoutEffect(() => {
    if (window) {
      setWMounted(true)
    }
  }, [])

  const [target, setTarget] = useState('_blank')

  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const colorBackground = () => {
    const colors = [
      '#00aeffe0',
      '#3369e7e0',
      '#8e43e7e0',
      '#b84592e0',
      '#ff4f81e0',
      '#ff6c5fe0',
      '#ffc168f0',
      '#2dde98e0',
      '#1cc7d0e0',
    ]
    const adjustedColor = props.totalData.map(
      (_, i) => colors[i % colors.length]
    )
    return adjustedColor
  }
  const productStyles = () => {
    switch (styles) {
      case 'style1':
        return (
          <div>
            <ProductOne
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )

      case 'style2':
        return (
          <div>
            <ProductTwo
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
              // productDetail={productDetail}
            />
          </div>
        )
      case 'style3':
        return (
          <div>
            <ProductThree
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style4':
        return (
          <div>
            <ProductFour
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style7':
        return (
          <div>
            <ProductSeven
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style9':
        return (
          <div>
            <ProductSeven
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style11':
        return (
          <div>
            <ProductEleven
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )

      case 'style14':
        return (
          <div>
            <ProductFourteen
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style15':
        return (
          <div>
            <ProductFifteen
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style16':
        return (
          <div>
            <ProductSixteen
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
              myIndex={props.myIndex}
              saveColor={colorBackground()}
              // color ={props.backgroundcolor}
            />
          </div>
        )
      case 'style12':
        return (
          <div>
            <ProductTwelve
              props={props}
              data={props.data}
              pimStyle={styles}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style13':
        return (
          <div>
            <ProductThirteen
              props={props}
              data={props.data}
              pimStyle={styles}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style17':
        return (
          <div>
            <ProductSeventeen
              props={props}
              data={props.data}
              styles={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      case 'style18':
        return (
          <div>
            <ProductEighteen
              props={props}
              data={props.data}
              pimStyle={styles}
              nobrorder={!props.noBorder}
              mode={props.mode}
              imgHeight={props.imgHeight}
              imgWidth={props.imgWidth}
            />
          </div>
        )
      default:
        return (
          <div
            ref={mounted}
            className={
              styles == 'style13'
                ? `${style.style_pad}`
                : `${style.card} ${
                    !props.noBorder ? style['card--border'] : ''
                  } no-select`
            }
            onClick={() => {}}
          >
            <Link
              href={link}
              passHref
              className="m-auto"
              style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '15px 0px',
                height: '250px',
              }}
              target={target}
            >
              <img
                className={`${style.image}`}
                src={`${
                  props.data.thumb ? props.data.thumb : props.data.image
                }`}
                alt="Product Card"
                //layout="fill"
                // objectFit="contain"
                // loader={() =>
                //   `${props.data.thumb ? props.data.thumb : props.data.image}`
                // }
                // blurDataURL={`${
                //   props.data.thumb ? props.data.thumb : props.data.image
                // }`}
                //placeholder="blur"
              />
            </Link>
            <div className={style.card__heart_icon}>
              <FavouriteButton
                //id={props.data.slug}
                item_code={props.data.item_code}
                slug={props.data.code}
              />
            </div>
            <div className={style.card__description}>
              <Link href={link} target={target}>
                <b>{props.data.name}</b>
                <small
                  className={`${style.product_variant} ${
                    props.data.option_value ? 'visible' : 'invisible'
                  }`}
                >
                  {props.data.option_value}
                </small>
                <div className={style.storage} />
                <div className={style.price}>
                  <span className="whitespace-nowrap">
                    {Number(props.data.price) > Number(props.data.special_price)
                      ? PriceFormat(Number(props.data.special_price))
                      : PriceFormat(Number(props.data.price))}
                    {/* {PriceFormat(
                  props.data.special_price == 0
                    ? props.data.price
                    : props.data.special_price
                )} */}
                  </span>
                  <span style={{ flex: 1 }} />
                  {props.data.rating && props.data.review_count > 0 ? (
                    <div>
                      <Star color="#f46b27" type="FILL" />
                      <b>{props.data.rating}</b>
                      <span className={`${style.review_color} ml-2`}>
                        ({props.data.review_count})
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {Number(props.data.special_price) !== 0 ? (
                  <div
                    className={`${style.savings} ${
                      Number(props.data.special_price) <
                      Number(props.data.price)
                        ? ''
                        : 'invisible'
                    }`}
                  >
                    <s>{PriceFormat(props.data.price)}</s>
                    {Math.round(
                      100 -
                        (Number(props.data.special_price) /
                          Number(props.data.price)) *
                          100
                    ) > 0 && (
                      <b>
                        {Math.round(
                          100 -
                            (Number(props.data.special_price) /
                              Number(props.data.price)) *
                              100
                        )}
                        % OFF
                      </b>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Link>
              <div className={style['product-tool-tip']}>{props.data.name}</div>
            </div>
          </div>
        )
    }
  }

  return (
    <div
      ref={mounted}
      className={` ${style.card} 
      } no-select`}
      onClick={() => {}}
    >
      {productStyles()}
    </div>
  )
}
