import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Img from '../../img/img'
import style from './productseventeen.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: string
}

const ProductSeventeen: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={` ${style.card} `}>
      <div className={style.rating_review}>
        {props.data.rating && props.data.review_count > 0 && (
          <div className={style.rating_review_count}>
            <Star color="#f46b27" type="FILL" />
            <span>{props.data.rating}</span>
          </div>
        )}
      </div>
      <div className={style.top_round}>
        <div className={style.inside_round}>
          <Link
            href={link}
            passHref
            target={target}
            className={`${style.imagess}`}
          >
            <Img
              rWidth={{
                mobileweb: 140,
                web: 163,
              }}
              rHeight={{
                mobileweb: 140,
                web: 163,
              }}
              deviceType={props.props.mode}
              //placeholder="blur"
              className={`${style.image}`}
              src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
              alt="Product Card"
              // layout="fill"
              // objectFit="contain"
              loader={() =>
                `${props.data.thumb ? props.data.thumb : props.data.image}`
              }
              blurDataURL={`${
                props.data.thumb ? props.data.thumb : props.data.image
              }`}
            />
          </Link>
        </div>
      </div>
      <div className={`${style.card__description}`}>
        <Link href={link} target={target}>
          <h3>{props.data.name}</h3>
          <h6>{props.data.option_value && props.data.option_value}</h6>
          <p className={style.price}>
            {sellingprice?.length != 0
              ? PriceFormat(sellingprice[0]?.price)
              : PriceFormat(mrpprice[0]?.price)}
          </p>

          <div className={style.card_price_discount}>
            <span className={style.span_left}>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              ) > 0 &&
                (Number(mrpprice[0]?.price) >= Number(sellingprice[0]?.price)
                  ? PriceFormat(mrpprice[0]?.price)
                  : PriceFormat(sellingprice[0]?.price))}
            </span>
            <span className={style.span_right}>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              ) > 0 && (
                <span>
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  )}
                  % OFF
                </span>
              )}
            </span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ProductSeventeen
