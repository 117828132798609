import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Star from '../../../../icons/star'
import Img from '~/components/ui/img/img'
import style from './product-list-view-one.module.scss'
import { mrpPrice, sellingPrice } from '~/util/price'

const ProductListViewOne = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={` ${style.card}`}>
      <Link href={link} passHref target={target}>
        <div className={style.card_grid}>
          <div className={style.card_left}>
            <Img
              rWidth={{
                mobileweb: 140,
                web: 140,
              }}
              rHeight={{
                mobileweb: 140,
                web: 140,
              }}
              deviceType={props?.mode}
              //placeholder="blur"
              src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
              alt="Product Card"
              // layout="fill"
              // objectFit="contain"
              loader={() =>
                `${props.data.thumb ? props.data.thumb : props.data.image}`
              }
              blurDataURL={`${
                props.data.thumb ? props.data.thumb : props.data.image
              }`}
            />
          </div>
          <div className={`${style.card_right}`}>
            <h3>{props.data.name}</h3>
            <h5>{props.data.option_value}</h5>
            {Number(sellingprice[0]?.price) !== 0 && (
              <div className={style.card_bottom}>
                <h4>
                  {sellingprice?.length != 0
                    ? PriceFormat(sellingprice[0]?.price)
                    : PriceFormat(mrpprice[0]?.price)}
                </h4>
                <div className={style.card_right_grid}>
                  <p>
                    {Number(mrpprice[0]?.price) >
                      Number(sellingprice[0]?.price) &&
                      PriceFormat(Number(mrpprice[0]?.price))}
                  </p>

                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  ) > 0 && (
                    <span>
                      (
                      {Math.round(
                        100 -
                          (Number(sellingprice[0]?.price) /
                            Number(mrpprice[0]?.price)) *
                            100
                      )}
                      % OFF)
                    </span>
                  )}
                </div>
              </div>
            )}
            {!!props.data.rating && props.data.review_count > 0 && (
              <div className={style.review_count}>
                <div className="flex items-center">
                  <Star color="#f46b27" type="FILL" size="0.8" />
                  <p>{props.data.rating}</p>
                </div>
                <span>({props.data.review_count} Reviews)</span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProductListViewOne
