import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Img from '../../img/img'
import style from './producttwelve.module.scss'
import { Star } from '../../icons/star'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import { Eye } from '../../icons/eye'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  pimStyle: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: string
}

const ProductTwelve: FC<ProductCardData> = (props) => {
  const style12 = props.styles

  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }

  const compare = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)

  return (
    <div className={`${style.card}`}>
      <Link href={link} target={target}>
        <h3>{props.data.name}</h3>
        <h4>{props.data.option_value}</h4>
      </Link>
      <Link href={link} target={target}>
        <Img
          rWidth={{
            mobileweb: 242,
            web: 242,
          }}
          rHeight={{
            mobileweb: 242,
            web: 242,
          }}
          deviceType={props.props.mode}
          //placeholder="blur"
          className={`${style.image}`}
          src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
          alt="Product Card"
          // layout="fill"
          // objectFit="contain"
          loader={() =>
            `${props.data.thumb ? props.data.thumb : props.data.image}`
          }
          blurDataURL={`${
            props.data.thumb ? props.data.thumb : props.data.image
          }`}
        />
      </Link>
      <div className={style.iconss}>
        <span className={style.eye_left}>
          <Link href={link} target={target}>
            {' '}
            <Eye color={'#fff'} strokeWidth={1} strokeColor={'#fff'}></Eye>
          </Link>
        </span>
        <span className={style.eye_right}>
          <FavouriteButton
            item_code={props.data.item_code}
            slug={props.data.code}
            pimStyle={props.pimStyle}
          />
        </span>
      </div>
      <Link href={link} target={target}>
        <div className={style.card__description}>
          <p>
            {sellingprice.length != 0
              ? PriceFormat(sellingprice[0]?.price)
              : PriceFormat(mrpprice[0]?.price)}
          </p>
          {Math.round(
            100 -
              (Number(sellingprice[0]?.price) / Number(mrpprice[0]?.price)) *
                100
          ) > 0 && (
            <span className={style.offer}>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              )}
              % OFF
            </span>
          )}
        </div>
        <div className={style.save_star}>
          {/* {Number(props.data.special_price) !== 0 && ( */}
          <p>
            {mrpprice[0]?.price < sellingprice[0]?.price
              ? ''
              : Number(mrpprice[0]?.price) - sellingprice[0]?.price
              ? `Save  ${PriceFormat(
                  Number(mrpprice[0]?.price) - sellingprice[0]?.price
                )}`
              : ''}
          </p>
          {/* )} */}
          {props.data.rating && props.data.review_count > 0 && (
            <div className={style.review_right}>
              <Star color="#4bb425" type="FILL" />
              <h5>{props.data.rating}</h5>
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default ProductTwelve
