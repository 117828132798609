import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import style from './productsixteen.module.scss'
import Star from '../../icons/star'
import Img from '../../img/img'
import { mrpPrice, sellingPrice } from '~/util/price'
type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: any
  myIndex?: any
  saveColor?: any
}

const ProductSixteen: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div className={`${style.card} `}>
      <Link href={link} passHref target={target}>
        <div className={style.img_rel}>
          <Img
            rWidth={{
              mobileweb: 131,
              web: 131,
            }}
            rHeight={{
              mobileweb: 131,
              web: 131,
            }}
            deviceType={props.props.mode}
            //placeholder="blur"
            className={`${style.image}`}
            src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
            alt="Product Card"
            // layout="fill"
            // objectFit="contain"
            loader={() =>
              `${props.data.thumb ? props.data.thumb : props.data.image}`
            }
            blurDataURL={`${
              props.data.thumb ? props.data.thumb : props.data.image
            }`}
          />
          {Number(mrpprice[0]?.price) - Number(sellingprice[0]?.price) !==
            0 && (
            <div
              className={style.savePrice}
              style={{ background: `${props.saveColor[props.myIndex]}` }}
            >
              <p>
                <span>Save</span>
                {PriceFormat(mrpprice[0]?.price - sellingprice[0]?.price)}
              </p>
            </div>
          )}
        </div>
        <div className={style.card__description}>
          <h3>{props.data.name}</h3>
          <h4>{props.data.option_value}</h4>
          <div className={style.price}>
            <p>
              {sellingprice?.length != 0
                ? PriceFormat(sellingprice[0]?.price)
                : PriceFormat(mrpprice[0]?.price)}
            </p>
            {!!props.data.rating && (
              <div className="flex items-center">
                <Star color="#f46b27" type="FILL" size={0.6} />
                <span>{props.data.rating}</span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProductSixteen
