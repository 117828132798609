// @ts-ignore
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useMounted } from '~/util/hooks'
import { GridWidthCalculator } from './layout-calc'
import {
  HorizontalScrollType,
  HorizontalScrollWrapper,
} from '~/components/ui/horizontal-scroll-wrapper/horizontal-scroll-wrapper'
import { IsMobile, PriceFormat } from '~/util'
import { ImageGroupProductCardlist } from '../../cards/imagegroup-product-card/imagegroup-product-cardlist'
interface Datum {
  __v: number
  _id: string
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  modifiedby: string
  modifieddate: string
  name: Name
  org_code?: any
  published: Published
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name {
  'en-IN': string
  'ta-IN': string
}

interface Image {
  height: number
  isDynamic: boolean
  shape: string
  url: string
  width: number
}
interface List {
  _id: string
  code: string
  image: Image
  name: string
  shape: string
  type: string
}

const ImageGroup = (props: any) => {
  const [target, setTarget] = useState('_blank')
  const ref = useRef(null)
  const [show, setShow] = useState(true)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      if (IsMobile() || window.innerWidth <= 550) {
        setShow(true)
      } else {
        setShow(false)
      }
    },
    []
  )
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])

  const [loader, setLoader] = useState(false)

  const mode = !!!props.mode
    ? {
        colspan: 0,
        isScroll: false,
        home_sidebanner: '',
        coulmnadjust: '',
      }
    : props.mode[props.deviceMode]

  const prodstyl = props.mode.web.layout
  const [width, setWidth] = useState(1200)
  const wrapper = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const changeWidth = () => {
      let w = GridWidthCalculator(wrapper)
      setWidth(w)
    }
    changeWidth()
    window.onreset = () => changeWidth()
  }, [])

  const gridLayout = () => {
    if (props.items?.length >= 1) {
      switch (mode.layout) {
        case 'NONE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
              buttoncustom={false}
              arrowvisible={props?.mode[props?.deviceMode]}
              count={props?.mode[props?.deviceMode].count}
            >
              {props.items?.length >= 1 &&
                props.items.map((res: any, i: number) => (
                  <div
                    className={`grid-row-1 rounded-lg   ${
                      props.mode[props.deviceMode].isBorder == true &&
                      'shadow border'
                    }`}
                    key={i}
                    style={{
                      backgroundColor: `${
                        props.mode[props.deviceMode].cardBgColor
                      }`,
                    }}
                  >
                    <ImageGroupProductCardlist
                      imgHeight="200px"
                      showCart
                      data={res}
                      onLike={() => {}}
                      prodstyl={prodstyl}
                      mode={props?.deviceMode}
                      title={props}
                    />
                  </div>
                ))}
            </HorizontalScrollWrapper>
          )
        case 'MEDIUM':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
            >
              <div className={`grid grid-cols-2 gap-1 m-2`}>
                {props.items?.length >= 1 &&
                  props.items?.slice(0, 4).map((res: any, i: number) => (
                    <div
                      className={`grid-row-1   ${
                        props.mode[props.deviceMode].isBorder == true &&
                        ' shadow border'
                      }`}
                      key={i}
                      style={{
                        backgroundColor: `${
                          props.mode[props.deviceMode].cardBgColor
                        }`,
                      }}
                    >
                      <ImageGroupProductCardlist
                        imgHeight="200px"
                        showCart
                        data={res}
                        onLike={() => {}}
                        prodstyl={prodstyl}
                        mode={props?.deviceMode}
                        title={props}
                      />
                    </div>
                  ))}
              </div>
            </HorizontalScrollWrapper>
          )
        case 'MEDIUM_THREE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
            >
              <>
                <div className={`grid grid-cols-1 gap-1 m-2`}>
                  {props.items?.length >= 1 &&
                    props.items?.slice(0, 1).map((res: any, i: number) => (
                      <div
                        className={`grid-row-1   ${
                          props.mode[props.deviceMode].isBorder == true &&
                          ' shadow border'
                        }`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
                <div className={`grid grid-cols-2 gap-1 m-2`}>
                  {props.items?.length >= 1 &&
                    props.items?.slice(1, 3).map((res: any, i: number) => (
                      <div
                        className={`grid-row-1   ${
                          props.mode[props.deviceMode].isBorder == true &&
                          ' shadow border'
                        }`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
              </>
            </HorizontalScrollWrapper>
          )
        case 'HORIZONTAL_MEDIUMTHREE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
            >
              <>
                <div className={`grid grid-cols-1 gap-1 mt-1`}>
                  {props.items?.length >= 1 &&
                    props.items?.slice(0, 1).map((res: any, i: number) => (
                      <div
                        className={`grid-row-1   ${
                          props.mode[props.deviceMode].isBorder == true &&
                          ' shadow border'
                        }`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
                <div className={`grid grid-cols-3 gap-1 mt-1`}>
                  {props.items?.length >= 1 &&
                    props.items?.slice(1, 4).map((res: any, i: number) => (
                      <div
                        className={`grid-row-1   ${
                          props.mode[props.deviceMode].isBorder == true &&
                          ' shadow border'
                        }`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
              </>
            </HorizontalScrollWrapper>
          )
        case 'VERTICAL_MEDIUMTHREE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
            >
              <div className="grid gap-1 grid-flow-col mb-5">
                <div
                  className={`grid place-items-center row-span-2 h-4/8 ${
                    props.mode[props.deviceMode].isBorder == true &&
                    'border border-gray-200'
                  } `}
                  style={{
                    backgroundColor: `${
                      props.mode[props.deviceMode].cardBgColor
                    }`,
                  }}
                >
                  {props.items?.length >= 1 &&
                    props.items?.slice(0, 1).map((res: any, i: number) => (
                      <div key={i}>
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
                <div className="col-span-2">
                  {props.items?.length >= 1 &&
                    props.items?.slice(1, 2).map((res: any, i: number) => (
                      <div
                        className={`grid-row-1   ${
                          props.mode[props.deviceMode].isBorder == true &&
                          ' shadow border border-gray-200 dark:bg-gray-800 dark:border-gray-700'
                        }`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
                <div className="col-span-2">
                  {props.items?.length >= 1 &&
                    props.items?.slice(2, 3).map((res: any, i: number) => (
                      <div
                        className={`grid-row-1  ${
                          props.mode[props.deviceMode].isBorder == true &&
                          'border border-gray-200  shadow dark:bg-gray-800 dark:border-gray-700'
                        }`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </HorizontalScrollWrapper>
          )

        case 'SMALL':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
            >
              <div className={`grid grid-cols-3 gap-1 m-2`}>
                {props.items?.length >= 1 &&
                  props.items?.slice(0, 9).map((res: any, i: number) => (
                    <div
                      className={`grid-row-1   ${
                        props.mode[props.deviceMode].isBorder == true &&
                        ' shadow border border-gray-200 dark:bg-gray-800 dark:border-gray-700'
                      }`}
                      key={i}
                      style={{
                        backgroundColor: `${
                          props.mode[props.deviceMode].cardBgColor
                        }`,
                      }}
                    >
                      <ImageGroupProductCardlist
                        imgHeight="200px"
                        showCart
                        data={res}
                        onLike={() => {}}
                        prodstyl={prodstyl}
                        mode={props?.deviceMode}
                        title={props}
                      />
                    </div>
                  ))}
              </div>
            </HorizontalScrollWrapper>
          )
        case 'HORIZONTAL_MEDIUMFOUR':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              sliderButton
              products
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              bgcolor={props.mode[props.deviceMode].bgColor}
              headbgcolor={props.mode[props.deviceMode].header.style.bgColor}
              headervisible={props.mode[props.deviceMode].isHeader}
              backimage={props.mode[props.deviceMode]?.bgImage?.url}
            >
              <div className={`grid grid-cols-4 gap-1 m-2`}>
                {props.items?.length >= 1 &&
                  props.items
                    ?.slice(0, 8, props.pagination.limit)
                    .map((res: any, i: number) => (
                      <div
                        className={`grid-row-1  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}
                        key={i}
                        style={{
                          backgroundColor: `${
                            props.mode[props.deviceMode].cardBgColor
                          }`,
                        }}
                      >
                        <ImageGroupProductCardlist
                          imgHeight="200px"
                          showCart
                          data={res}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                          title={props}
                        />
                      </div>
                    ))}
              </div>
            </HorizontalScrollWrapper>
          )
        default:
          return loader ? '' : <></>
      }
    }
  }
  return <div ref={wrapper}>{gridLayout()}</div>
}
export default ImageGroup
