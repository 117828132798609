import Link from 'next/link'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import { PriceFormat, IsMobile } from '~/util'
import { FavouriteButton } from '../../favourite-button/favourite-button'
import { HomeProductCardListProps } from '../home-product-card/home-product-cardlist.interface'
import style from '../home-product-card/home-product-cardlist.module.scss'
import { Star } from '../../icons/star'

/**
 * product card renders Product card this will have fav icon and add to card button
 * @category Components
 * @subcategory ProductCardlist
 * @param {ProductCardListProps} props product card related data
 * @returns {JSX.Element} jsx value
 */
export const ProductEight: FC<HomeProductCardListProps> = (props: any) => {
  const [target, setTarget] = useState('_blank')
  const ref = useRef(null)
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data.slug,
    },
  }
  const loadDataOnlyOnce = () => {}
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
      loadDataOnlyOnce()
    }
    if (ref.current) {
      ref.current.style.color = '#3b3b3b'
      ref.current.style.fontSize = '11px'
      ref.current.style.fontWeight = '500'
      ref.current.style.paddingLeft = '8px'
    }
  }, [])
  return (
    <div className="">
      <div className={`${style.image_style}`}>
        {/* <div className={`${style.heart_icon}`}>
          <FavouriteButton
            item_code={props.data.item_code}
            slug={props.data.code}
          />
        </div> */}
        <Link href={link} target={target}>
          <Img
            rWidth={{
              mobileweb: 150,
              web: 150,
            }}
            rHeight={{
              mobileweb: 150,
              web: 150,
            }}
            deviceType={props.mode}
            src={`${
              props.data.thumb
                ? props.data.thumb
                : props.data.image
                ? props.data.image
                : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
            }`}
            alt="thumb"
            blurDataURL={`${
              props.data.thumb
                ? props.data.thumb
                : props.data.image
                ? props.data.image
                : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
            }`}
            //placeholder="blur"
          />
        </Link>
        <div
          className={`${style.prop_rel} ${
            props.prodstyl == 'NONE' ? '' : `${style.layout_center}`
          }`}
        >
          <div className={style.pos_abs_desc}>{props.data.name}</div>
          <Link href={link} target={target}>
            <div>
              <h3>{props.data.name}</h3>
              <h4
                style={{
                  visibility: `${
                    props.data.option_value ? 'visible' : 'hidden'
                  }`,
                }}
              >
                {props.data.option_value}
              </h4>

              <div
                className={`${style.card_top} ${
                  props.prodstyl == 'NONE' ? '' : `${style.grid_price}`
                }`}
              >
                <h5>
                  {props.data.price > props.data.special_price
                    ? PriceFormat(props.data.special_price)
                    : PriceFormat(props.data.price)}
                </h5>
                <span>
                  {props.data.discount > 0 && (
                    <span
                      style={{
                        fontSize: '10px',
                        background: '#FF6C00 0% 0% no-repeat padding-box',
                        padding: '4px',
                        color: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      {props.data.discount + '% OFF'}
                    </span>
                  )}
                </span>
              </div>
              <div>
                {!!props.data.rating && props.data.review_count > 0 && (
                  <b className={`flex items-center `}>
                    <Star color="#f46b27" type="FILL" />
                    <b style={{ color: 'black', fontSize: '13px' }}>
                      {props.data.rating}
                    </b>
                    <span className="ml-1" style={{ fontSize: '13px' }}>
                      ({props.data.review_count})
                    </span>
                  </b>
                )}
              </div>
            </div>
            {/* <div
              className={`${style.card_bottom} ${
                props.prodstyl == 'NONE' ? '' : `${style.grid_price}`
              }`}
            >
              <span 
              className='line-through'
                style={{
                  visibility: `${props.data.discount ? 'visible' : 'hidden'}`,fontSize:'12px'
                }}
              >
                {PriceFormat(props.data.price)}
              </span>
            
            </div> */}
          </Link>
        </div>
      </div>
    </div>
  )
}
