// @ts-ignore
// @ts-nocheck
import React, {
  useState,
  useEffect,
  FC,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react'
import { useMounted } from '~/util/hooks'
import { ProductCard } from '../../cards/product-card/product-card'
import { ProductList } from './Product-card/Product-card'
import { GridWidthCalculator, PIMLayout } from './layout-calc'
import {
  HorizontalScrollType,
  HorizontalScrollWrapper,
} from '~/components/ui/horizontal-scroll-wrapper/horizontal-scroll-wrapper'
import { HomeProductCardlist } from '../../cards/home-product-card/home-product-cardlist'
import { HomeProductCard } from '../../cards/horizontal-vertical-card/home-product-card'
import { Verticalcard } from '../../cards/horizontal-vertical-card/productvertical'
import {
  HorizontalScrollTypes,
  HorizontalScrollWrappers,
} from '../../horizontal-scroll-wrappers/horizontal-scroll-wrappers'
import ProductFive from '../../cards/product-cards/product-five'
import style from './../../../../styles/home.module.scss'
import { ProductEight } from '../../cards/product-cards/product-eight'
import { StyleHorizontalScrollWrappers } from '../../style-horizontal-scroll-wrapper/style-horizantal-scroll-wrapper'
import { IsMobile, PriceFormat } from '~/util'
import Img from '../../img/img'
import styles from './../../cards/product-cards/productsix.module.scss'
import styless from './../../cards/product-cards/productfive.module.scss'
import ProductListViewOne from '../../cards/product-cards/product-list-view-cards/product-list-view-one/product-listview-one'
import ProductListViewTwo from '../../cards/product-cards/product-list-view-cards/product-list-view-two/product-listview-two'
interface Datum {
  __v: number
  _id: string
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  modifiedby: string
  modifieddate: string
  name: Name
  org_code?: any
  published: Published
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name {
  'en-IN': string
  'ta-IN': string
}

interface Image {
  height: number
  isDynamic: boolean
  shape: string
  url: string
  width: number
}
interface List {
  _id: string
  code: string
  image: Image
  name: string
  shape: string
  type: string
}

const Productlist = (props: any) => {
  const [target, setTarget] = useState('_blank')
  const ref = useRef(null)
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  const loadDataOnlyOnce = () => {}
  const [show, setShow] = useState(true)
  const [dynamicandCategory, setDynamicandCategory] = useState([])
  const [dynamicandBrand, setDynamicandBrand] = useState([])
  const [dynamicorCategory, setDynamicorCategory] = useState([])
  const [dynamicorBrand, setDynamicorBrand] = useState([])
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      if (IsMobile() || window.innerWidth <= 550) {
        setShow(true)
      } else {
        setShow(false)
      }
    },
    []
  )
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
      loadDataOnlyOnce()
    }
    if (ref.current) {
      ref.current.style.color = '#3b3b3b'
      ref.current.style.fontSize = '11px'
      ref.current.style.fontWeight = '500'
      ref.current.style.paddingLeft = '8px'
    }
  }, [])

  const [loader, setLoader] = useState(false)

  const mode = !!!props.mode
    ? {
        colspan: 0,
        isScroll: false,
        home_sidebanner: '',
        coulmnadjust: '',
      }
    : props.mode[props.deviceMode]
  const productstyl = props.mode[props.deviceMode].style

  const prodstyl = props.mode.web.layout
  const productStyless = props.mode[props.deviceMode].style
  const Dynamiclink = () => {
    let arr2 = props?.dynamic?.and
      ?.map((category) => category.type === 'CATEGORY' && category.val)
      .filter((item) => !!item)
    let arr3 = props?.dynamic?.or
      ?.map((category) => category.type === 'CATEGORY' && category.val)
      .filter((item) => !!item)
    let arr4 = props?.dynamic?.and
      ?.map((category) => category.type === 'BRAND' && category.val)
      .filter((item) => !!item)
    let arr5 = props?.dynamic?.or
      ?.map((brand) => brand.type === 'BRAND' && brand.val)
      .filter((item) => !!item)
    setDynamicandCategory(arr2)
    setDynamicandBrand(arr4)
    setDynamicorCategory(arr3)
    setDynamicorBrand(arr5)
  }

  const [width, setWidth] = useState(1200)
  const wrapper = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const changeWidth = () => {
      let w = GridWidthCalculator(wrapper)
      setWidth(w)
    }
    changeWidth()
    window.onreset = () => changeWidth()
    Dynamiclink()
  }, [])
  const gridLayout = () => {
    if (props.items?.length >= 1) {
      switch (mode.layout) {
        case 'NONE':
          if (mode.style == 'none') {
            return (
              <div className={`flex gap-1 ${mode.style == 'style3' ? '' : ''}`}>
                <HorizontalScrollWrapper
                  type={HorizontalScrollType.NORMAL}
                  productstyl={productstyl}
                  showAllLink={{
                    pathname: `/s`,
                    query: {
                      categories: `${
                        props.type.toLowerCase() == 'group'
                          ? `group_code:=[\`${props?.grp?.code}\`]`
                          : props.type.toLowerCase() == 'family'
                          ? `family:=[\`${props?.family?.name}\`]`
                          : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                      }`,
                    },
                  }}
                  sliderButton
                  products
                  //scrollLength={360}
                  title={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.title
                  }
                  image={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.image
                  }
                  proplist={props?.mode[props.deviceMode].header}
                  headerhtml={props?.type}
                >
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(0, props?.pagination?.limit)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          mode={props?.deviceMode}
                          prodstyl={prodstyl}
                        />
                      ))}
                </HorizontalScrollWrapper>
              </div>
            )
          } else if (mode.style == 'style5') {
            return (
              <div className={`gap-1`}>
                <HorizontalScrollWrappers
                  type={HorizontalScrollType.NORMAL}
                  showAllLink={{
                    pathname: `/s`,
                    query: {
                      categories: `${
                        props.type.toLowerCase() == 'group'
                          ? `group_code:=[\`${props?.grp?.code}\`]`
                          : props.type.toLowerCase() == 'family'
                          ? `family:=[\`${props?.family?.name}\`]`
                          : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                      } `,
                    },
                  }}
                  sliderButton
                  products
                  //scrollLength={360}
                  title={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.title
                  }
                  image={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.image
                  }
                  proplist={props?.mode[props.deviceMode].header}
                  buttoncustom={true}
                >
                  <>
                    {props.items?.slice(0, 1).map((res: any, i: number) => (
                      <div
                        key={i}
                        className={` col-span-4 grid-cols-1 border-b ${styless.just} `}
                      >
                        <div className="">
                          <div className={`${styless.desc}  text-black `}>
                            {res.name}
                          </div>
                          <div className={styless.product_pri}>
                            {res.mrp.price > res.special_price
                              ? PriceFormat(res.special_price)
                              : PriceFormat(res.mrp.price)}
                          </div>
                        </div>
                        <div>
                          <img
                            style={{ height: '20vh' }}
                            src={`${res.image.url}`}
                            alt="thumb"
                            //placeholder="blur"
                          />
                        </div>
                      </div>
                    ))}
                    {props.items?.slice(1, 4).map((res, i: number) => (
                      <div
                        key={i}
                        className=" inline-block overflow-hidden border-r"
                        style={{ padding: '15px' }}
                      >
                        <img
                          style={{ width: '100%' }}
                          src={`${res.image.url}`}
                          alt="thumb"
                          //placeholder="blur"
                        />
                        <p className={`${styless.product_namee} truncate`}>
                          {res.name}
                        </p>
                        <div className={styless.product_pri1}>
                          {res.mrp.price > res.special_price
                            ? PriceFormat(res.special_price)
                            : PriceFormat(res.mrp.price)}
                        </div>
                      </div>
                    ))}
                  </>
                </HorizontalScrollWrappers>
              </div>
            )
          } else if (mode.style == 'style6') {
            return (
              <div className={`mt-6`}>
                <HorizontalScrollWrapper
                  type={HorizontalScrollType.NORMAL}
                  showAllLink={{
                    pathname: `/s`,
                    query: {
                      categories: `${
                        props.type.toLowerCase() == 'group'
                          ? `group_code:=[\`${props?.grp?.code}\`]`
                          : props.type.toLowerCase() == 'family'
                          ? `family:=[\`${props?.family?.name}\`]`
                          : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                      } `,
                    },
                  }}
                  sliderButton
                  products
                  //scrollLength={360}
                  title={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.title
                  }
                  image={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.image
                  }
                  proplist={props?.mode[props.deviceMode].header}
                  buttoncustom={true}
                >
                  <div className="grid grid-row-3 grid-flow-col">
                    <div
                      className={` text-white grid place-content-center row-span-3 col-span-2 border-r `}
                      style={{ padding: '0px 9px 0px' }}
                    >
                      {props.items?.map((res: any, i: number) => (
                        <div key={i}>
                          {i == 0 && (
                            <>
                              <div className="">
                                <img
                                  src={`${res.image.url}`}
                                  alt="thumb"
                                  //placeholder="blur"
                                />
                              </div>

                              <b
                                className={`${styles.product_name} text-ellipsis overflow-hidden ...`}
                              >
                                {res.name}
                              </b>

                              <div className="flex items-center justify-between ">
                                <div className={styles.product_pri}>
                                  {res.mrp.price > res.special_price
                                    ? PriceFormat(res.special_price)
                                    : PriceFormat(res.mrp.price)}
                                </div>
                                <div
                                  className={`${styles.savings} ${
                                    Number(res.special_price) <
                                    Number(res.price)
                                      ? ''
                                      : 'invisible'
                                  }`}
                                >
                                  {Math.round(
                                    100 -
                                      (Number(res.special_price) /
                                        Number(res.price)) *
                                        100
                                  ) > 0 && (
                                    <b className={styles.savings}>
                                      (
                                      {Math.round(
                                        100 -
                                          (Number(res.special_price) /
                                            Number(res.price)) *
                                            100
                                      )}
                                      % OFF)
                                    </b>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="p-2 text-white grid  col-span-2 border-b">
                      {props.items?.map((res: any, i: number) => (
                        <div key={i}>
                          {i == 1 && (
                            <>
                              <div className="">
                                <img
                                  style={{ width: '100px', margin: '0 auto' }}
                                  src={`${res.image.url}`}
                                  alt="thumb"
                                  //placeholder="blur"
                                />
                              </div>

                              <b
                                className={`${styles.product_name} text-ellipsis overflow-hidden ...`}
                              >
                                {res.name}
                              </b>
                              <div className="flex items-center justify-between">
                                <div className={styles.product_pri}>
                                  {res.mrp.price > res.special_price
                                    ? PriceFormat(res.special_price)
                                    : PriceFormat(res.mrp.price)}
                                </div>

                                <div
                                  className={`${styles.savings} ${
                                    Number(res.special_price) <
                                    Number(res.price)
                                      ? ''
                                      : 'invisible'
                                  }`}
                                >
                                  {Math.round(
                                    100 -
                                      (Number(res.special_price) /
                                        Number(res.price)) *
                                        100
                                  ) > 0 && (
                                    <b className={styles.savings}>
                                      (
                                      {Math.round(
                                        100 -
                                          (Number(res.special_price) /
                                            Number(res.price)) *
                                            100
                                      )}
                                      % OFF)
                                    </b>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="p-2  text-white grid  col-span-2">
                      {props.items?.map((res: any, i: number) => (
                        <div key={i}>
                          {i == 2 && (
                            <>
                              <div className="">
                                <img
                                  style={{ width: '100px', margin: '0 auto' }}
                                  src={`${res.image.url}`}
                                  alt="thumb"
                                  //placeholder="blur"
                                />
                              </div>
                              <b
                                className={`${styles.product_name} text-ellipsis overflow-hidden ...`}
                              >
                                {res.name}
                              </b>
                              <div className="flex items-center justify-between">
                                <div className={styles.product_pri}>
                                  {res.mrp.price > res.special_price
                                    ? PriceFormat(res.special_price)
                                    : PriceFormat(res.mrp.price)}
                                </div>
                                <div
                                  className={`${styles.savings} ${
                                    Number(res.special_price) <
                                    Number(res.price)
                                      ? ''
                                      : 'invisible'
                                  }`}
                                >
                                  {Math.round(
                                    100 -
                                      (Number(res.special_price) /
                                        Number(res.price)) *
                                        100
                                  ) > 0 && (
                                    <b className={styles.savings}>
                                      (
                                      {Math.round(
                                        100 -
                                          (Number(res.special_price) /
                                            Number(res.price)) *
                                            100
                                      )}
                                      % OFF)
                                    </b>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </HorizontalScrollWrapper>
              </div>
            )
          } else if (mode.style == 'style8') {
            return (
              <HorizontalScrollWrapper
                type={HorizontalScrollType.NORMAL}
                showAllLink={{
                  pathname: `/s`,
                  query: {
                    categories: `${
                      props.type.toLowerCase() == 'group'
                        ? `group_code:=[\`${props?.grp?.code}\`]`
                        : props.type.toLowerCase() == 'family'
                        ? `family:=[\`${props?.family?.name}\`]`
                        : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                    } `,
                  },
                }}
                sliderButton
                products
                //scrollLength={360}
                title={
                  props?.mode[props.deviceMode].isHeader == true &&
                  props?.mode[props.deviceMode].header?.title
                }
                image={
                  props?.mode[props.deviceMode].isHeader == true &&
                  props?.mode[props.deviceMode].header?.image
                }
                proplist={props?.mode[props.deviceMode].header}
                buttoncustom={true}
              >
                <div
                  className={`flex gap-1 ${mode.style == 'style3' ? '' : ''}`}
                >
                  <div className={`grid grid-cols-2 gap-1 m-2`}>
                    {props.items?.length >= 1 &&
                      props.items
                        ?.slice(0, 8)
                        .map((res: any, i: number) => (
                          <ProductEight
                            linkPrefix={`${String(res?.name).replace(
                              / /g,
                              '-'
                            )}`}
                            imgHeight="140px"
                            showCart
                            data={res}
                            key={i}
                            onLike={() => {}}
                            mode={props?.deviceMode}
                            prodstyl={prodstyl}
                            modeStyle={mode.style}
                          />
                        ))}
                  </div>
                </div>
              </HorizontalScrollWrapper>
            )
          } else if (
            mode.style == 'style9'
              ? mode.style == 'style9'
              : mode.style == 'style7'
          ) {
            return (
              <div className={`flex gap-1`}>
                <StyleHorizontalScrollWrappers
                  type={HorizontalScrollTypes.NORMAL}
                  showAllLink={{
                    pathname: `/s`,
                    query: {
                      categories: `${
                        props.type.toLowerCase() == 'group'
                          ? `group_code:=[\`${props?.grp?.code}\`]`
                          : props.type.toLowerCase() == 'family'
                          ? `family:=[\`${props?.family?.name}\`]`
                          : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                      } `,
                    },
                  }}
                  sliderButton
                  products
                  scrollLength={360}
                  title={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.title
                  }
                  image={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.image
                  }
                  proplist={props?.mode[props.deviceMode].header}
                >
                  {props.items
                    ?.slice(0, props.pagination.limit)
                    .map((res, i: number) => (
                      <ProductCard
                        linkPrefix={`${String(res.name).replace(/ /g, '-')}`}
                        imgHeight="230px"
                        imgWidth="230px"
                        showCart
                        data={res}
                        key={i}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                        productStyles={mode.style}
                        totalData={props.items}
                      />
                    ))}
                </StyleHorizontalScrollWrappers>
              </div>
            )
          } else if (mode.style == 'style10') {
            return (
              <HorizontalScrollWrapper
                type={HorizontalScrollType.NORMAL}
                showAllLink={{
                  pathname: `/s`,
                  query: {
                    categories: `${
                      props.type.toLowerCase() == 'group'
                        ? `group_code:=[\`${props?.grp?.code}\`]`
                        : props.type.toLowerCase() == 'family'
                        ? `family:=[\`${props?.family?.name}\`]`
                        : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                    } `,
                  },
                }}
                sliderButton
                products
                //scrollLength={360}
                title={
                  props?.mode[props.deviceMode].isHeader == true &&
                  props?.mode[props.deviceMode].header?.title
                }
                image={
                  props?.mode[props.deviceMode].isHeader == true &&
                  props?.mode[props.deviceMode].header?.image
                }
                proplist={props?.mode[props.deviceMode].header}
                buttoncustom={true}
              >
                <div
                  className={`flex gap-1 ${mode.style == 'style3' ? '' : ''}`}
                >
                  <div className={`grid grid-cols-2 gap-1 m-2`}>
                    {props.items?.length >= 1 &&
                      props.items
                        ?.slice(0, 4)
                        .map((res: any, i: number) => (
                          <ProductEight
                            linkPrefix={`${String(res?.name).replace(
                              / /g,
                              '-'
                            )}`}
                            imgHeight="140px"
                            showCart
                            data={res}
                            key={i}
                            onLike={() => {}}
                            mode={props?.deviceMode}
                            prodstyl={prodstyl}
                            modeStyle={mode.style}
                          />
                        ))}
                  </div>
                </div>
              </HorizontalScrollWrapper>
            )
          } else if (mode.style == 'style19') {
            return (
              <div className={style.listviewone}>
                {props.items?.length >= 1 &&
                  props.items?.slice(0, 3).map((res: any, i: number) => (
                    <div key={i}>
                      <ProductListViewOne
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        showCart
                        data={res}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                        prodstyl={prodstyl}
                        modeStyle={mode.style}
                      />
                    </div>
                  ))}
              </div>
            )
          } else if (mode.style == 'style20') {
            return (
              <div className={style.listviewone}>
                {props.items?.length >= 1 &&
                  props.items?.slice(0, 3).map((res: any, i: number) => (
                    <div key={i}>
                      <ProductListViewTwo
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        showCart
                        data={res}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                      />
                    </div>
                  ))}
              </div>
            )
          } else {
            return (
              <div className={`flex gap-1`}>
                <HorizontalScrollWrappers
                  productStyless={productStyless}
                  type={HorizontalScrollTypes.NORMAL}
                  productstyl={productstyl}
                  showAllLink={{
                    pathname: `/s`,
                    query: {
                      categories: `${
                        props.type.toLowerCase() == 'group'
                          ? `group_code:=[\`${props?.grp?.code}\`]`
                          : props.type.toLowerCase() == 'family'
                          ? `family:=[\`${props?.family?.name}\`]`
                          : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                      } `,
                    },
                  }}
                  sliderButton
                  products
                  scrollLength={360}
                  title={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.title
                  }
                  image={
                    props?.mode[props.deviceMode].isHeader == true &&
                    props?.mode[props.deviceMode].header?.image
                  }
                  proplist={props?.mode[props.deviceMode].header}
                >
                  {props.items
                    ?.slice(0, props.pagination.limit)
                    .map((res, i: number) => (
                      <ProductCard
                        linkPrefix={`${String(res.name).replace(/ /g, '-')}`}
                        imgHeight="140px"
                        imgWidth="140px"
                        showCart
                        data={res}
                        key={i}
                        myIndex={i}
                        onLike={() => {}}
                        productStyles={productStyless}
                        mode={props?.deviceMode}
                        totalData={props.items}
                      />
                    ))}
                </HorizontalScrollWrappers>
              </div>
            )
          }
        case 'MEDIUM':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              productstyl={productstyl}
              showAllLink={{
                pathname: `/s`,
                query: {
                  categories: `${
                    props.type.toLowerCase() == 'group'
                      ? `group_code:=[\`${props?.grp?.code}\`]`
                      : props.type.toLowerCase() == 'family'
                      ? `family:=[\`${props?.family?.name}\`]`
                      : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                  } `,
                },
              }}
              sliderButton
              products
              //scrollLength={360}
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              headerhtml={props?.type}
            >
              <div className={`grid grid-cols-2 gap-1 m-2`}>
                {props.items?.length >= 1 &&
                  props.items
                    ?.slice(0, 4, props.pagination.limit)
                    .map((res: any, i: number) => (
                      <HomeProductCardlist
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        imgHeight="140px"
                        showCart
                        data={res}
                        key={i}
                        onLike={() => {}}
                        prodstyl={prodstyl}
                        mode={props?.deviceMode}
                      />
                    ))}
              </div>
            </HorizontalScrollWrapper>
          )
        case 'MEDIUM_THREE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              productstyl={productstyl}
              showAllLink={{
                pathname: `/s`,
                query: {
                  categories: `${
                    props.type.toLowerCase() == 'group'
                      ? `group_code:=[\`${props?.grp?.code}\`]`
                      : props.type.toLowerCase() == 'family'
                      ? `family:=[\`${props?.family?.name}\`]`
                      : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                  } `,
                },
              }}
              sliderButton
              products
              //scrollLength={360}
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              headerhtml={props?.type}
            >
              <>
                <div className={`grid grid-cols-1 gap-1 m-2`}>
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(0, 1)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className={`grid grid-cols-2 gap-1 m-2`}>
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(0, 2)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
              </>
            </HorizontalScrollWrapper>
          )
        case 'HORIZONTAL_MEDIUMTHREE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              productstyl={productstyl}
              showAllLink={{
                pathname: `/s`,
                query: {
                  categories: `${
                    props.type.toLowerCase() == 'group'
                      ? `group_code:=[\`${props?.grp?.code}\`]`
                      : props.type.toLowerCase() == 'family'
                      ? `family:=[\`${props?.family?.name}\`]`
                      : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                  } `,
                },
              }}
              sliderButton
              products
              //scrollLength={360}
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              headerhtml={props?.type}
            >
              <>
                <div className={`grid grid-cols-1 gap-1 mt-1`}>
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(0, 1)
                      .map((res: any, i: number) => (
                        <HomeProductCard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className={`grid grid-cols-3 gap-1 mt-1`}>
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(0, 3)
                      .map((res: any, i: number) => (
                        <HomeProductCardlist
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgHeight="140px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
              </>
            </HorizontalScrollWrapper>
          )
        case 'VERTICAL_MEDIUMTHREE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              productstyl={productstyl}
              showAllLink={{
                pathname: `/s`,
                query: {
                  categories: `${
                    props.type.toLowerCase() == 'group'
                      ? `group_code:=[\`${props?.grp?.code}\`]`
                      : props.type.toLowerCase() == 'family'
                      ? `family:=[\`${props?.family?.name}\`]`
                      : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                  } `,
                },
              }}
              sliderButton
              products
              //scrollLength={360}
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              headerhtml={props?.type}
            >
              <div className="grid gap-1 grid-flow-col mb-5">
                <div className="grid place-items-center border border-gray-200 row-span-2 h-4/8">
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(0, 1)
                      .map((res: any, i: number) => (
                        <Verticalcard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgWidth="180px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className="col-span-2 border border-gray-200">
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(1, 2)
                      .map((res: any, i: number) => (
                        <Verticalcard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgWidth="180px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
                <div className="col-span-2 border border-gray-200">
                  {props.items?.length >= 1 &&
                    props.items
                      ?.slice(2, 3)
                      .map((res: any, i: number) => (
                        <Verticalcard
                          linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                          imgWidth="180px"
                          showCart
                          data={res}
                          key={i}
                          onLike={() => {}}
                          prodstyl={prodstyl}
                          mode={props?.deviceMode}
                        />
                      ))}
                </div>
              </div>
            </HorizontalScrollWrapper>
          )
        case 'SMALL':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              productstyl={productstyl}
              showAllLink={{
                pathname: `/s`,
                query: {
                  categories: `${
                    props.type.toLowerCase() == 'group'
                      ? `group_code:=[\`${props?.grp?.code}\`]`
                      : props.type.toLowerCase() == 'family'
                      ? `family:=[\`${props?.family?.name}\`]`
                      : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                  } `,
                },
              }}
              sliderButton
              products
              //scrollLength={360}
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              headerhtml={props?.type}
            >
              <div className={`grid grid-cols-3 gap-1 m-2`}>
                {props.items?.length >= 1 &&
                  props.items
                    ?.slice(0, 9, props.pagination.limit)
                    .map((res: any, i: number) => (
                      <HomeProductCardlist
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        imgHeight="140px"
                        showCart
                        data={res}
                        key={i}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                      />
                    ))}
              </div>
            </HorizontalScrollWrapper>
          )
        case 'HORIZONTAL_MEDIUMFOUR':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              productstyl={productstyl}
              showAllLink={{
                pathname: `/s`,
                query: {
                  categories: `${
                    props.type.toLowerCase() == 'group'
                      ? `group_code:=[\`${props?.grp?.code}\`]`
                      : props.type.toLowerCase() == 'family'
                      ? `family:=[\`${props?.family?.name}\`]`
                      : `(categories_slug:=[${dynamicandCategory}] && brand_code:=[${dynamicandBrand}]) || (categories_slug:=[${dynamicorCategory}] || brand_code:=[${dynamicorBrand}])`
                  } `,
                },
              }}
              sliderButton
              products
              //scrollLength={360}
              title={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.title
              }
              image={
                props?.mode[props.deviceMode].isHeader == true &&
                props?.mode[props.deviceMode].header?.image
              }
              proplist={props?.mode[props.deviceMode].header}
              headerhtml={props?.type}
            >
              <div className={`grid grid-cols-4 gap-1 m-2`}>
                {props.items?.length >= 1 &&
                  props.items
                    ?.slice(0, 8, props.pagination.limit)
                    .map((res: any, i: number) => (
                      <HomeProductCardlist
                        linkPrefix={`${String(res?.name).replace(/ /g, '-')}`}
                        imgHeight="140px"
                        showCart
                        data={res}
                        key={i}
                        onLike={() => {}}
                        mode={props?.deviceMode}
                      />
                    ))}
              </div>
            </HorizontalScrollWrapper>
          )
        default:
          return loader ? '' : <></>
      }
    }
  }
  return (
    <div ref={wrapper} className={props.className}>
      {mode.layout == 'NONE' ? '' : <ProductList data={props} />}
      {gridLayout()}
    </div>
  )
}
export default Productlist
