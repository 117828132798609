// @ts-ignore
// @ts-nocheck
import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { PimHomeService } from '~/services/pim.service'
import { GridWidthCalculator } from './layout-calc'
import GridLayout from 'react-grid-layout'
import Link from 'next/link'
import style from './category.module.scss'
import { IsMobile } from '~/util'
import Img from './../../img/img'

interface Datum {
  __v: number
  _id: string
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  modifiedby: string
  modifieddate: string
  name: Name2
  org_code?: any
  published: Published
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name2 {
  all: string
  'en-IN': string
  'ta-IN': string
}

interface List {
  _id: string
  ancestors: Ancestor[]
  code: string
  name: Name
  image?: Image
}

interface Image {
  height?: number
  isDynamic?: boolean
  shape?: string
  url?: string
  width?: number
}

interface Ancestor {
  _id: string
  code: string
  name: Name
  pages: string[]
}

interface Name {
  'en-IN': string
  'ta-IN': string
}

type HeadContentProps = {
  image: string
  title: string
}
type CategoryContentProps = {
  image: string
  title: string
}
const Categorylist = (props) => {
  const mode = useMemo(() => {
    if (!!!props.mode) {
      return {
        colspan: 0,
        isScroll: false,
        home_sidebanner: '',
        coulmnadjust: '',
      }
    }
    return props.mode[props.deviceMode]
  }, [props, props.deviceMode])
  const [loader, setLoader] = useState(false)

  const [_width, setWidth] = useState(1200)

  const wrapper = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const changeWidth = () => {
      let w = GridWidthCalculator(wrapper)
      setWidth(w)
    }
    changeWidth()
    window.onreset = () => changeWidth()
  }, [wrapper])
  const HeadContent: FC<HeadContentProps> = (props) => {
    return (
      <div
        className={`${
          props.mode[props.deviceMode].isHeader == true ? 'block' : 'hidden'
        }`}
      >
        <Link
          href={{
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: props?.grp?.code,
            },
          }}
          className="flex align-middle items-center mt-3 mb-3 justify-center"
        >
          {props.mode[props.deviceMode].header?.image && (
            <Img
              rWidth={{
                mobileweb: 50,
                web: 50,
              }}
              rHeight={{
                mobileweb: 50,
                web: 50,
              }}
              deviceType={props.deviceMode}
              src={
                !!props.mode[props.deviceMode].header?.image
                  ? props.mode[props.deviceMode].header?.image
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              loader={() =>
                !!props.mode[props.deviceMode].header?.image
                  ? props.mode[props.deviceMode].header?.image
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              blurDataURL={
                !!props.mode[props.deviceMode].header?.image
                  ? props.mode[props.deviceMode].header?.image
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              //placeholder="blur"
              alt="image"
            />
          )}
          <span
            className="font-extrabold text-2xl pl-4 inline-block"
            style={{
              color: props?.mode[props.deviceMode].header?.style?.fontColor,
              fontSize: props?.mode[props.deviceMode].header?.style?.fontSize,
              fontWeight:
                props?.mode[props.deviceMode].header?.style?.fontStyle
                  ?.isBold && 'bold',
              fontStyle:
                props?.mode[props.deviceMode].header?.style?.fontStyle
                  ?.isItalic && 'italic',
              textDecoration:
                props?.mode[props.deviceMode].header?.style?.fontStyle
                  ?.isUnderLine && 'underline',
            }}
          >
            {props.mode[props.deviceMode].header?.title}
          </span>
        </Link>
      </div>
    )
  }
  const gridLayout = () => {
    if (props?.items?.length > 1) {
      switch (mode.layout) {
        case 'NONE':
          if (mode.isScroll == true) {
            return <CategoryContent {...props} />
          } else {
            return (
              <div className={`ml-5`}>
                {props.items?.map((e: any, id: number) => (
                  <div key={id} className="inline-block  align-top mb-4 p-2">
                    <Link
                      href={{
                        pathname: '/[slug]/page',
                        query: {
                          type: 'category',
                          slug: e.code,
                        },
                      }}
                      rel={'canonical'}
                      className={`${style.brand_link}`}
                    >
                      <Img
                        rWidth={{
                          mobileweb: 50,
                          web: 50,
                        }}
                        rHeight={{
                          mobileweb: 50,
                          web: 50,
                        }}
                        deviceType={props.deviceMode}
                        src={
                          !!e.image?.url
                            ? e.image && e.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        className={` ${style.category_image} ${
                          style[mode.style]
                        } m-auto`}
                        loader={() =>
                          !!e.image?.url
                            ? e.image && e.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        blurDataURL={
                          !!e.image?.url
                            ? e.image && e.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        //placeholder="blur"
                        alt="Noimage"
                      />
                      <span className="text-sm font-medium block whitespace-normal text-center mt-3 text-gray-500 leading-none">
                        {e.name}
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            )
          }

        case 'MEDIUM':
          if (mode.isScroll == false) {
            return (
              <div className={`grid grid-cols-2 gap-1`}>
                {props.items?.slice(0, 4).map((item1: any, _i: number) => (
                  <div key={_i} className={`mb-5  m-1 rounded-2xl flex-1 ...`}>
                    <Link
                      href={
                        item1?.pages?.length > 0 && item1.pages != 'undefined'
                          ? {
                              pathname: '/[slug]/page',
                              query: {
                                type: 'cateogry',
                                slug: item1.code,
                              },
                            }
                          : {
                              pathname: `/s`,
                              query: {
                                categories_slug: `categories_slug:=[\`${item1.code}\`]`,
                              },
                            }
                      }
                      className={`${style.brand_link}`}
                    >
                      <Img
                        rWidth={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        rHeight={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        deviceType={props.deviceMode}
                        src={
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        className={` ${style[mode.style]} m-auto`}
                        loader={() =>
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        blurDataURL={
                          !!item1.image?.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        //placeholder="blur"
                        alt="Noimage"
                      />
                      <h4
                        className={`text-center capitalize text-base font-medium p-2`}
                      >
                        {item1.code}
                      </h4>
                    </Link>
                  </div>
                ))}
              </div>
            )
          } else {
            return <CategoryContent {...props} />
          }
        case 'SMALL':
          if (mode.isScroll == true) {
            return <CategoryContent {...props} />
          } else {
            return (
              <div className={`grid grid-cols-3 gap-1`}>
                {props.items?.slice(0, 6).map((item1: any, _i: number) => (
                  <div key={_i} className={`mb-5  m-1 rounded-2xl flex-1 ...`}>
                    <Link
                      href={
                        item1?.pages?.length > 0 && item1.pages != 'undefined'
                          ? {
                              pathname: '/[slug]/page',
                              query: {
                                type: 'category',
                                slug: item1.code,
                              },
                            }
                          : {
                              pathname: `/s`,
                              query: {
                                categories_slug: `categories_slug:=[\`${item1.code}\`]`,
                              },
                            }
                      }
                      className={`${style.brand_link}`}
                    >
                      <Img
                        rWidth={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        rHeight={{
                          mobileweb: 50,
                          web: 85,
                        }}
                        deviceType={props.deviceMode}
                        src={
                          !!item1.image.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        className={` ${style[mode.style]} m-auto`}
                        loader={() =>
                          !!item1.image.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        blurDataURL={
                          !!item1.image.url
                            ? item1.image && item1.image?.url
                            : 'https://img.poorvika.com/common/Noimage.png'
                        }
                        //placeholder="blur"
                        alt="Noimage"
                      />
                      <h4
                        className={`text-center capitalize text-base font-medium p-2`}
                      >
                        {item1.code}
                      </h4>
                    </Link>
                  </div>
                ))}
              </div>
            )
          }
        case 'MEDIUM_THREE':
          if (mode.isScroll == true) {
            return <CategoryContent {...props} />
          } else {
            return (
              <div>
                <div className={`grid grid-cols-1 gap-1`}>
                  {props.items.slice(0, 1).map((item: any, _i: number) => (
                    <div
                      key={_i}
                      className={`mb-5  m-1 rounded-2xl flex-1 ...`}
                    >
                      <Link
                        href={
                          item?.pages?.length > 0 && item.pages != 'undefined'
                            ? {
                                pathname: '/[slug]/page',
                                query: {
                                  type: 'category',
                                  slug: item.code,
                                },
                              }
                            : {
                                pathname: `/s`,
                                query: {
                                  categories_slug: `categories_slug:=[\`${item.code}\`]`,
                                },
                              }
                        }
                        className={`${style.brand_link}`}
                      >
                        <Img
                          rWidth={{
                            mobileweb: 50,
                            web: 85,
                          }}
                          rHeight={{
                            mobileweb: 50,
                            web: 85,
                          }}
                          deviceType={props.deviceMode}
                          src={
                            !!item.image?.url
                              ? item.image && item.image?.url
                              : 'https://img.poorvika.com/common/Noimage.png'
                          }
                          className={`m-auto ${style[mode.style]} ${
                            mode.layout == 'MEDIUM_THREE' ? 'block' : 'hidden'
                          }`}
                          loader={() =>
                            !!item.image?.url
                              ? item.image && item.image?.url
                              : 'https://img.poorvika.com/common/Noimage.png'
                          }
                          blurDataURL={
                            !!item.image?.url
                              ? item.image && item.image?.url
                              : 'https://img.poorvika.com/common/Noimage.png'
                          }
                          //placeholder="blur"
                          alt="Noimage"
                        />
                        <h4
                          className={`capitalize text-center text-base font-medium p-2 `}
                        >
                          {item.code}
                        </h4>
                      </Link>
                    </div>
                  ))}
                </div>
                <div
                  className={`grid grid-cols-2 gap-1 ${
                    mode.layout == 'MEDIUM_THREE' ? 'block' : 'hidden'
                  }`}
                >
                  {props.items?.slice(1, 3).map((item: any, _i: number) => (
                    <div
                      key={_i}
                      className={`mb-5  m-1 rounded-2xl flex-1 ...`}
                    >
                      <Link
                        href={
                          item?.pages?.length > 0 && item.pages != 'undefined'
                            ? {
                                pathname: '/[slug]/page',
                                query: {
                                  type: 'category',
                                  slug: item.code,
                                },
                              }
                            : {
                                pathname: `/s`,
                                query: {
                                  categories_slug: `categories_slug:=[\`${item.code}\`]`,
                                },
                              }
                        }
                        className={`${style.brand_link}`}
                      >
                        <Img
                          rWidth={{
                            mobileweb: 50,
                            web: 85,
                          }}
                          rHeight={{
                            mobileweb: 50,
                            web: 85,
                          }}
                          deviceType={props.deviceMode}
                          src={
                            !!item.image?.url
                              ? item.image && item.image?.url
                              : 'https://img.poorvika.com/common/Noimage.png'
                          }
                          className={`${style[mode.style]} m-auto`}
                          loader={() =>
                            !!item.image?.url
                              ? item.image && item.image?.url
                              : 'https://img.poorvika.com/common/Noimage.png'
                          }
                          blurDataURL={
                            !!item.image?.url
                              ? item.image && item.image?.url
                              : 'https://img.poorvika.com/common/Noimage.png'
                          }
                          //placeholder="blur"
                          alt="Noimage"
                        />
                        <h4
                          className={`text-center capitalize text-base font-medium p-2`}
                        >
                          {item.code}
                        </h4>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )
          }
        default:
          return loader ? '' : <></>
      }
    }
  }

  const CategoryContent: FC<CategoryContentProps> = (props) => (
    <div
      className={` ${style.category_scroll} pt-4 pb-4 overflow-x-scroll overscroll-y-none whitespace-nowrap text-center ${style.overflow_menu}`}
    >
      {props.items?.map((e: any, id: number) => (
        <div key={id} className="inline-block w-1/5 align-top m-1">
          <Link
            href={{
              pathname: '/[slug]/page',
              query: {
                type: 'category',
                slug: e.code,
              },
            }}
            rel={'canonical'}
            className={`${style.brand_link}`}
          >
            <Img
              rWidth={{
                mobileweb: 50,
                web: 50,
              }}
              rHeight={{
                mobileweb: 50,
                web: 50,
              }}
              deviceType={props.deviceMode}
              src={
                !!e?.image?.url
                  ? e?.image && e?.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              className={` ${style.category_imagenone} m-auto`}
              loader={() =>
                !!e.image?.url
                  ? e?.image && e?.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              blurDataURL={
                !!e.image?.url
                  ? e?.image && e?.image?.url
                  : 'https://img.poorvika.com/common/Noimage.png'
              }
              //placeholder="blur"
              alt="Noimage"
            />
            <span className="text-sm block whitespace-normal text-center mt-3 text-gray-500 leading-4">
              {e.name}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )

  return (
    <div ref={wrapper} className={props.className}>
      <HeadContent {...props} />
      {gridLayout()}
    </div>
  )
}
export default Categorylist
