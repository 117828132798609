import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { FC } from 'react'
import { CloseNew } from '~/components/ui/icons/close_new'
import Img from '~/components/ui/img/img'
import { InnerHtml } from '~/components/ui/inner-html/inner-html'
import Model from '~/components/ui/model/model'
import { VideoCards } from '../video-cards/video-card'
import style from './../../../../../styles/pim.module.scss'
import { fetchThumbnail } from '~/util/video-image'

export const VideoList: FC<any> = (props) => {
  const [selectedVideo, setSelectedVideo] = useState(null as any)
  const [play, setPlay] = useState(false)
  const [playOne, setPlayOne] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState('' as any)
  const [videoType, setVideoType] = useState({} as any)

  useEffect(() => {
    const fetchThumbnailUrl = async () => {
      if (props.data.playerType === 'Video') {
        const url = await fetchThumbnail(props.data.url)

        setThumbnailUrl(url)
      }
    }
    fetchThumbnailUrl()
  }, [])

  const hasVideo = useCallback(() => {
    return !!selectedVideo
  }, [selectedVideo])
  const variantClassMap: any = {
    Youtube: 'https://www.youtube.com/embed/',
    Vimeo: 'https://player.vimeo.com/video/',
    Dailymotion: 'https://www.dailymotion.com/embed/video/',
  }
  const [modedata, setModeData] = useState('web')
  useLayoutEffect(() => {
    const cb = () => {
      if (!!window) {
        if (window.innerWidth && window.innerWidth <= 768) {
          return 'mobileweb'
        } else {
          return 'web'
        }
      }
      return 'web'
    }
    let mData = cb()
    setModeData(mData)
  }, [props])

  const mode = useMemo(() => {
    if (!!!props.mode) {
      return {
        colspan: 0,
        isScroll: false,
        home_sidebanner: '',
        coulmnadjust: '',
      }
    }
    return props.mode[modedata]
  }, [props, modedata])

  const videoStyle = () => {
    switch (mode?.style) {
      case 'none':
        return (
          <div>
            <VideoCards
              play={() => {
                setSelectedVideo(
                  `${variantClassMap[props.data.playerType]}${
                    props.data.videoID
                  }?autoplay=${props.data.loop ? 1 : 0}&loop=${
                    props.data.loop ? 1 : 0
                  }&controls=${props.data.controls ? 1 : 0}&playlist=${
                    props.data.videoID
                  }`
                )
                setVideoType(props.data)
              }}
              thumbnailUrl={thumbnailUrl}
              data={props}
            />
            {hasVideo() ? (
              <div>
                <Model
                  closeModel={() => setSelectedVideo(null)}
                  model={hasVideo()}
                >
                  <div
                    className={`${style.shareclose}`}
                    onClick={() => setSelectedVideo(!hasVideo)}
                  >
                    <CloseNew className={`${style.closebutton}`} />
                  </div>
                  {videoType.playerType == 'Video' ? (
                    <video
                      className={style.home_iframe}
                      src={videoType.url}
                      // title="Video player"
                      controls={true}
                    />
                  ) : (
                    <iframe
                      className={style.home_iframe}
                      src={selectedVideo}
                      title="YouTube video player"
                      allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  )}
                </Model>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      case 'style1':
        const imageVariantMap: any = {
          Youtube: `https://img.youtube.com/vi/${props.data?.videoID}/0.jpg`,
          Vimeo: `https://vumbnail.com/${props.data?.videoID}.jpg`,
          Dailymotion: `https://www.dailymotion.com/thumbnail/video/${props.data?.videoID}`,
          Video: thumbnailUrl,
        }
        return (
          <div>
            {play == false && (
              <div className={style.card}>
                <div className={style.card__video}>
                  <div
                    className={style.card__video__thumbnail}
                    style={{
                      backgroundImage: `url(${
                        imageVariantMap[props.data?.playerType]
                      })`,
                    }}
                  />

                  <img
                    onClick={() => setPlay(!play)}
                    src="https://img.poorvika.com//play_video.png"
                    // loader={() => `https://img.poorvika.com//play_video.png`}
                    // blurDataURL={`https://img.poorvika.com//play_video.png`}
                    //placeholder="blur"
                    alt="play_video"
                  />
                </div>
                {props.data.description && (
                  <div className={style.card__description}>
                    <div className={style.card__description__text}>
                      <b>
                        <InnerHtml data={props.data.description} />
                      </b>
                    </div>
                  </div>
                )}
              </div>
            )}

            {play ? (
              <div>
                <iframe
                  width="100%"
                  height="300px"
                  src={`${variantClassMap[props.data.playerType]}${
                    props.data.videoID
                  }?&autoplay=1`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      case 'style2':
        const imageVariantMapOne: any = {
          Youtube: `https://img.youtube.com/vi/${props.data?.videoID}/0.jpg`,
          Vimeo: `https://vumbnail.com/${props.data?.videoID}.jpg`,
          Dailymotion: `https://www.dailymotion.com/thumbnail/video/${props.data?.videoID}`,
          Video: thumbnailUrl,
        }
        return (
          <div>
            {playOne == false && (
              <div className={style.card}>
                <div className={style.card__video}>
                  <div
                    className={style.card__video__thumbnail}
                    style={{
                      backgroundImage: `url(${
                        imageVariantMapOne[props.data?.playerType]
                      })`,
                    }}
                  />

                  <img
                    onClick={() => setPlayOne(!playOne)}
                    src="https://img.poorvika.com//play_video.png"
                    // loader={() => `https://img.poorvika.com//play_video.png`}
                    // blurDataURL={`https://img.poorvika.com//play_video.png`}
                    //placeholder="blur"
                    alt="Noimage"
                  />
                </div>
                {props.data.description && (
                  <div className={style.card__description}>
                    <div className={style.card__description__text}>
                      <b>
                        <InnerHtml data={props.data.description} />
                      </b>
                    </div>
                  </div>
                )}
              </div>
            )}

            {playOne ? (
              <div>
                <iframe
                  className={style.playOnewid}
                  src={`${variantClassMap[props.data.playerType]}${
                    props.data.videoID
                  }?&autoplay=1`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      default:
        return (
          <div>
            <VideoCards
              play={() => {
                setSelectedVideo(
                  `${variantClassMap[props.data.playerType]}${
                    props.data.videoID
                  }?autoplay=${props.data.loop ? 1 : 0}&loop=${
                    props.data.loop ? 1 : 0
                  }&controls=${props.data.controls ? 1 : 0}&playlist=${
                    props.data.videoID
                  }`
                )
                setVideoType(props.data)
              }}
              thumbnailUrl={thumbnailUrl}
              data={props}
            />
            {hasVideo() ? (
              <div>
                <Model
                  closeModel={() => setSelectedVideo(null)}
                  model={hasVideo()}
                >
                  <div
                    className={`${style.shareclose}`}
                    onClick={() => setSelectedVideo(!hasVideo)}
                  >
                    <CloseNew className={`${style.closebutton}`} />
                  </div>

                  {videoType.playerType == 'Video' ? (
                    <video
                      className={style.home_iframe}
                      src={videoType.url}
                      // title="Video player"
                      controls={true}
                    />
                  ) : (
                    <iframe
                      className={style.home_iframe}
                      src={selectedVideo}
                      title="YouTube video player"
                      allow="fullscreen; accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  )}
                </Model>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
    }
  }
  return <div>{videoStyle()}</div>
}
