import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Img from '../../img/img'
import style from './productone.module.scss'
import { Star } from '../../icons/star'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: any
  imgWidth?: string
}

const ProductOne: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div
      className={` ${style.card} ${
        !props.props.noBorder ? style['card--border'] : ''
      } no-select`}
      onClick={() => {}}
    >
      <Link
        href={link}
        passHref
        target={target}
        className={`m-auto ${style.container}`}
        style={{
          position: 'relative',
        }}
      >
        <Img
          style={{
            // height: !!props.props.imgHeight ? props.props.imgHeight : '100%',
            width: !!props.props.imgWidth ? props.props.imgWidth : '100%',
            margin: '0 auto',
            paddingTop: '20px',
          }}
          rWidth={{
            mobileweb: 300,
            web: 450,
          }}
          rHeight={{
            mobileweb: 300,
            web: 450,
          }}
          deviceType={props.props.mode}
          //placeholder="blur"
          className={`${style.image}`}
          src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
          alt="Product Card"
          // layout="fill"
          // objectFit="contain"
          loader={() =>
            `${props.data.thumb ? props.data.thumb : props.data.image}`
          }
          blurDataURL={`${
            props.data.thumb ? props.data.thumb : props.data.image
          }`}
        />
      </Link>
      <div className="">
        {props.data.rating && props.data.review_count > 0 ? (
          <div className={style.card__heart_icon}>
            <Star color="#f46b27" type="FILL" />
            <b>{props.data.rating}</b>
            <span className={`${style.review_color} ml-2`}>
              ({props.data.review_count})
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={`${style.card__description} `}>
        <Link href={link} target={target}>
          <b className="text-ellipsis overflow-hidden ...">{props.data.name}</b>
          <small
            className={`${style.product_variant} ${
              props.data.option_value ? 'visible' : 'invisible h-4'
            }`}
          >
            {props.data.option_value}
          </small>
          <div className={style.storage} />
          <div className={style.price}>
            <span className={`whitespace-nowrap ${style.colorprice}`}>
              {Number(mrpprice[0]?.price) > Number(sellingprice[0]?.price)
                ? PriceFormat(Number(sellingprice[0]?.price))
                : PriceFormat(Number(mrpprice[0]?.price))}
              <s className={`ml-1  ${style.colorprice1}`}>
                {PriceFormat(mrpprice[0]?.price)}
              </s>
            </span>

            <span className={`${style.savings} `}>
              {Math.round(
                100 -
                  (Number(sellingprice[0]?.price) /
                    Number(mrpprice[0]?.price)) *
                    100
              ) > 0 ? (
                <b className={`${style.savings}`}>
                  (
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  )}
                  % OFF)
                </b>
              ) : (
                <b className={`${style.savings} invisible`}>
                  (
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  )}
                  % OFF)
                </b>
              )}
            </span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ProductOne
