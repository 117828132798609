import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'
import { IsMobile, PriceFormat } from '~/util'
import Star from '../../icons/star'
import Img from '../../img/img'
import style from './producttwo.module.scss'
import { mrpPrice, sellingPrice } from '~/util/price'

type ProductCardData = {
  props: any
  data: any
  styles: string
  nobrorder: any
  mode?: string
  imgHeight?: string
  imgWidth?: string
}

const ProductFour: FC<ProductCardData> = (props) => {
  const [target, setTarget] = useState('_blank')
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])
  const link = {
    pathname: '/[slug]/p',
    query: {
      slug: props.data?.slug,
    },
  }
  let sellingprice = sellingPrice(props?.data?.prices[0]?.sp)
  let mrpprice = mrpPrice(props?.data?.mrp[0]?.price)
  return (
    <div
      className={`${style.card} ${
        !props.props.noBorder ? style['card--border'] : ''
      } no-select`}
      onClick={() => {}}
    >
      <div>
        <Link
          href={link}
          passHref
          className={`m-auto ${style.container}`}
          style={{
            width: '100%',
            position: 'relative',
          }}
          target={target}
        >
          <Img
            style={{
              // height: !!props.props.imgHeight ? props.props.imgHeight : '100%',
              width: !!props.props.imgWidth ? props.props.imgWidth : '100%',
              margin: '0 auto',
              paddingTop: '20px',
            }}
            rWidth={{
              mobileweb: 300,
              web: 450,
            }}
            rHeight={{
              mobileweb: 300,
              web: 450,
            }}
            deviceType={props.props.mode}
            //placeholder="blur"
            className={`${style.image}`}
            src={`${props.data.thumb ? props.data.thumb : props.data.image}`}
            alt="Product Card"
            // layout="fill"
            // objectFit="contain"
            loader={() =>
              `${props.data.thumb ? props.data.thumb : props.data.image}`
            }
            blurDataURL={`${
              props.data.thumb ? props.data.thumb : props.data.image
            }`}
          />

          {/* <div className={style.overlay}>
              <div className={style.text}>
                <Img
                  src={`${
                    props.data.thumb ? props.data.thumb : props.data.image
                  }`}
                  alt=""
                />
              </div>
            </div> */}
        </Link>

        <div className={style.card__description}>
          <Link href={link} target={target}>
            <div className="flex items-center ">
              {/* <span className="whitespace-nowrap text-sm text-gray-400  ">
                Best Seller
              </span> */}

              <span style={{ flex: 1 }} />
              <div>
                <div
                  className={`flex ${
                    props.data.rating && props.data.review_count > 0
                      ? ''
                      : 'invisible h-5'
                  }`}
                >
                  <s></s>

                  {props.data.rating && props.data.review_count > 0 ? (
                    <div className={`flex items-center `}>
                      <Star color="black" type="FILL" size="0.7" />
                      <b className="text-sm">{props.data.rating}</b>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <b className="truncate break-normal ...">{props.data.name}</b>
            <small
              className={`${style.product_variant} ${
                props.data.option_value ? 'visible' : 'invisible'
              }`}
            >
              {props.data.option_value}
            </small>
            <div className={style.storage} />
            <div className="flex items-center mt-3">
              <span className="text-sm  text-orange-200 font-bold">
                {Number(mrpprice[0]?.price) > Number(sellingprice[0]?.price)
                  ? PriceFormat(Number(sellingprice[0]?.price))
                  : PriceFormat(Number(mrpprice[0]?.price))}
              </span>

              <span style={{ flex: 1 }} />
              {Number(sellingprice[0]?.price) !== 0 ? (
                <div
                  className={`${style.savingss} ${
                    Number(sellingprice[0]?.price) < Number(mrpprice[0]?.price)
                      ? ''
                      : 'invisible'
                  }`}
                >
                  {Math.round(
                    100 -
                      (Number(sellingprice[0]?.price) /
                        Number(mrpprice[0]?.price)) *
                        100
                  ) > 0 && (
                    <b className="">
                      {Math.round(
                        100 -
                          (Number(sellingprice[0]?.price) /
                            Number(mrpprice[0]?.price)) *
                            100
                      )}
                      % OFF
                    </b>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProductFour
