// @ts-ignore
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useMounted } from '~/util/hooks'
import { GridWidthCalculator } from './layout-calc'
import {
  HorizontalScrollType,
  HorizontalScrollWrapper,
} from '~/components/ui/horizontal-scroll-wrapper/horizontal-scroll-wrapper'
import { IsMobile } from '~/util'
import { ImageGroupProductCardlist } from '../../cards/imagegroup-product-card/imagegroup-product-cardlist'
import Imagelist from './imagelist'
interface Datum {
  __v: number
  _id: string
  channel: string
  code: string
  createdby: string
  createddate: string
  isActive: boolean
  list: List[]
  modifiedby: string
  modifieddate: string
  name: Name
  org_code?: any
  published: Published
}

interface Published {
  datetime: string
  status: string
  version: number
}

interface Name {
  'en-IN': string
  'ta-IN': string
}

interface Image {
  height: number
  isDynamic: boolean
  shape: string
  url: string
  width: number
}
interface List {
  _id: string
  code: string
  image: Image
  name: string
  shape: string
  type: string
}

const TabImageGroup = (props: any) => {
  const [_target, setTarget] = useState('_blank')
  const [_show, setShow] = useState(true)
  const parentRef = useRef(null)
  useMounted(
    parentRef,
    () => {
      if (IsMobile() || window.innerWidth <= 550) {
        setShow(true)
      } else {
        setShow(false)
      }
    },
    []
  )
  useEffect(() => {
    if (window) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setTarget('_self')
      }
      if (IsMobile()) {
        setTarget('_self')
      }
    }
  }, [])

  const [loader, setLoader] = useState(false)
  const [_width, setWidth] = useState(1200)
  const wrapper = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const changeWidth = () => {
      let w = GridWidthCalculator(wrapper)
      setWidth(w)
    }
    changeWidth()
    window.onreset = () => changeWidth()
  }, [])

  const gridLayout = () => {
    if (
      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items?.length >= 1
    ) {
      switch (
        props?.props?.tabpaneltype?.tabcontent?.imageListComp?.mode[props.mode]
          ?.layout
      ) {
        case 'NONE':
          return (
            <HorizontalScrollWrapper
              type={HorizontalScrollType.NORMAL}
              props
              sliderButton
              products
            >
              {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                ?.length >= 1 &&
                props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items.map(
                  (res: any, i: number) => (
                    <div
                      className={`grid-row-1  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}
                      key={i}
                    >
                      <ImageGroupProductCardlist
                        imgHeight="200px"
                        showCart
                        data={res}
                        onLike={() => {}}
                        mode={props?.mode}
                        title={props}
                      />
                    </div>
                  )
                )}
            </HorizontalScrollWrapper>
          )
        case 'LAYOUT1':
          return (
            <>
              <div
                className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
              >
                <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                  <div
                    className={`${
                      props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-9'
                    }`}
                  >
                    <div className="grid grid-cols-4 gap-4">
                      {props?.props?.tabpaneltype?.tabcontent?.imageListComp
                        .items?.length >= 1 &&
                        props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                          .slice(0, 4)
                          .map((i: any, j: number) => (
                            <div key={j} className={`col-span-2 ...`}>
                              <Imagelist
                                props={i}
                                mode={props.mode}
                                title={
                                  props?.props?.tabpaneltype?.tabcontent
                                    ?.imageListComp
                                }
                              />
                            </div>
                          ))}
                    </div>
                  </div>
                  <div
                    className={`${
                      props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-3'
                    }`}
                  >
                    <Imagelist
                      props={
                        props?.props?.tabpaneltype?.tabcontent?.imageListComp
                          .items[4]
                      }
                      mode={props.mode}
                      title={
                        props?.props?.tabpaneltype?.tabcontent?.imageListComp
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )
        case 'LAYOUT2':
          return (
            <div
              className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
            >
              <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div className="grid grid-cols-1 gap-4">
                    {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                        .slice(0, 1)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <Imagelist
                              props={i}
                              mode={props.mode}
                              title={
                                props?.props?.tabpaneltype?.tabcontent
                                  ?.imageListComp
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div className="grid grid-cols-2 gap-4">
                    {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                        .slice(1, 5)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <Imagelist
                              props={i}
                              mode={props.mode}
                              title={
                                props?.props?.tabpaneltype?.tabcontent
                                  ?.imageListComp
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          )
        case 'LAYOUT3':
          return (
            <div
              className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
            >
              <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-3'
                  }`}
                >
                  <div
                    className={`grid ${
                      props.mode == 'mobileweb' ? 'grid-cols-2' : 'grid-cols-1'
                    } gap-4`}
                  >
                    {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                        .slice(0, 2)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <Imagelist
                              props={i}
                              mode={props.mode}
                              title={
                                props?.props?.tabpaneltype?.tabcontent
                                  ?.imageListComp
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div className="grid grid-cols-1 gap-4">
                    {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                        .slice(2, 3)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <Imagelist
                              props={i}
                              mode={props.mode}
                              title={
                                props?.props?.tabpaneltype?.tabcontent
                                  ?.imageListComp
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-3'
                  }`}
                >
                  <div
                    className={`grid ${
                      props.mode == 'mobileweb' ? 'grid-cols-2' : 'grid-cols-1'
                    } gap-4`}
                  >
                    {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                        .slice(3, 6)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <Imagelist
                              props={i}
                              mode={props.mode}
                              title={
                                props?.props?.tabpaneltype?.tabcontent
                                  ?.imageListComp
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          )

        case 'LAYOUT4':
          return (
            <>
              <div className="flex">
                <div className={`flex-1`}>
                  <Imagelist
                    props={
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp
                        .items[0]
                    }
                    mode={props.mode}
                    title={
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp
                    }
                  />
                </div>
              </div>
              <div
                className={`${
                  props.mode == 'mobileweb' ? 'grid grid-cols-12' : 'flex'
                }`}
              >
                {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                  ?.length >= 1 &&
                  props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                    .slice(1, props.mode == 'mobileweb' ? 7 : 6)
                    .map((i: any, j: number) => (
                      <div
                        key={j}
                        className={`${
                          props.mode == 'mobileweb' ? 'col-span-6' : 'flex-1'
                        }`}
                      >
                        <Imagelist
                          props={i}
                          mode={props.mode}
                          title={
                            props?.props?.tabpaneltype?.tabcontent
                              ?.imageListComp
                          }
                        />
                      </div>
                    ))}
              </div>
            </>
          )
        case 'LAYOUT5':
          return (
            <div
              className={`mt-1 mb-4 grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-3`}
            >
              <div className={'col-span-12 grid gap-3 grid-cols-12'}>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <div className="grid grid-cols-2 gap-4">
                    {props?.props?.tabpaneltype?.tabcontent?.imageListComp.items
                      ?.length >= 1 &&
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp?.items
                        .slice(0, 4)
                        .map((i: any, j: number) => (
                          <div key={j} className="...">
                            <Imagelist
                              props={i}
                              mode={props.mode}
                              title={
                                props?.props?.tabpaneltype?.tabcontent
                                  ?.imageListComp
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className={`${
                    props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                  }`}
                >
                  <Imagelist
                    props={
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp
                        .items[4]
                    }
                    mode={props.mode}
                    title={
                      props?.props?.tabpaneltype?.tabcontent?.imageListComp
                    }
                  />
                </div>
              </div>
            </div>
          )

        case 'LAYOUT6':
          return (
            <div className="grid grid-cols-12 gap-4">
              <div
                className={`${
                  props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                }`}
              >
                <Imagelist
                  props={
                    props?.props?.tabpaneltype?.tabcontent?.imageListComp
                      .items[0]
                  }
                  mode={props.mode}
                  title={props?.props?.tabpaneltype?.tabcontent?.imageListComp}
                />
              </div>
              <div
                className={`${
                  props.mode == 'mobileweb' ? 'col-span-12' : 'col-span-6'
                }`}
              >
                <Imagelist
                  props={
                    props?.props?.tabpaneltype?.tabcontent?.imageListComp
                      .items[1]
                  }
                  mode={props.mode}
                  title={props?.props?.tabpaneltype?.tabcontent?.imageListComp}
                />
              </div>
            </div>
          )

        default:
          return loader ? '' : <></>
      }
    }
  }
  return <div ref={wrapper}>{gridLayout()}</div>
}
export default TabImageGroup
