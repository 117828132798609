import Link from 'next/link'
import React from 'react'
import Img from '../../img/img'

const Imagelist = (data: any) => {
  const Images = (imageurl: any) => {
    return (
      <Img
        rWidth={{
          mobileweb: imageurl?.props?.document?.attr?.width,
          web: imageurl?.props?.document?.attr?.width,
        }}
        rHeight={{
          mobileweb: imageurl?.props?.document?.attr?.height,
          web: imageurl?.props?.document?.attr?.height,
        }}
        deviceType={imageurl.mode}
        src={`${
          imageurl.props.document.attr.url
            ? imageurl.props.document.attr.url
            : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
        }`}
        alt="thumb"
        blurDataURL={`${
          imageurl.props.document.attr.url
            ? imageurl.props.document.attr.url
            : 'https://img.poorvika.com/common/NoImageAvailable.jpg'
        }`}
        //placeholder="blur"
        style={{ width: '100%' }}
      />
    )
  }
  const Title = (title: any) => {
    return (
      <div>
        <h3
          style={{
            color: title.title?.mode[title?.mode]?.title?.fontColor,
            fontSize: title.title?.mode[title?.mode]?.title?.fontSize,
            fontWeight:
              title.title?.mode[title?.mode]?.title?.fontStyle?.isBold &&
              'bold',
            fontStyle:
              title.title?.mode[title?.mode]?.title?.fontStyle?.isItalic &&
              'italic',
            textDecoration:
              title.title?.mode[title?.mode]?.title?.fontStyle?.isUnderLine &&
              'underline',
            textAlign: 'center',
          }}
        >
          {title?.props?.document.title}
        </h3>
        <h4
          style={{
            visibility: `${
              title?.props?.document.subTitle ? 'visible' : 'hidden'
            }`,
            color: title.title?.mode[title?.mode]?.subtitle?.fontColor,
            fontSize: title.title?.mode[title?.mode]?.subtitle?.fontSize,
            fontWeight:
              title.title?.mode[title?.mode]?.subtitle?.fontStyle?.isBold &&
              'bold',
            fontStyle:
              title.title?.mode[title?.mode]?.subtitle?.fontStyle?.isItalic &&
              'italic',
            textDecoration:
              title.title?.mode[title?.mode]?.subtitle?.fontStyle
                ?.isUnderLine && 'underline',
            textAlign: 'center',
          }}
        >
          {title?.props?.document.subTitle}
        </h4>
      </div>
    )
  }
  const bannerLink = (element: any) => {
    switch (element.linktype) {
      case 'EXTERNAL':
        return `${element.link.url}`
      case 'PRODUCT':
        if (element.product.pd_type == 'VARIANT') {
          return {
            pathname: '/[slug]/p',
            query: {
              slug: element.product.code,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories_slug: `categories_slug:=[\`${element.product.code}\`]`,
            },
          }
        }
      case 'TAG':
        if (element.tag?.tagtype == 'PRODUCT') {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        } else {
          return {
            pathname: `/s`,
            query: {
              categories: `tags:=[\`${element?.tag?.code}\`]`,
            },
          }
        }

      case 'PAGE':
        if (element.page.pg_type == 'GENERAL') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'group',
              slug: element.page.code,
            },
          }
        } else if (element.page.pg_type == 'CATEGORY') {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'category',
              slug: element.page.code,
            },
          }
        } else {
          return {
            pathname: '/[slug]/page',
            query: {
              type: 'brand',
              slug: element.page.code,
            },
          }
        }

      default:
        return `${element.slug}`
    }
  }
  const Lnk = (props: any) => {
    const { children } = props
    return props.linktype == 'NONE' ? (
      <>{children}</>
    ) : (
      <Link
        href={bannerLink(props)}
        passHref
        target={props.link?.target}
        className={props.className}
      >
        {children}
      </Link>
    )
  }

  return (
    <Lnk {...data.props.document}>
      <Images {...data} />
      <Title {...data} />
    </Lnk>
  )
}
export default Imagelist
